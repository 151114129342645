import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppUtils } from "../../models/common/appUtils";
import * as employeeGroupService from "../../services/employeeGroupService";
import * as salaryPeriodService from "../../services/salaryPeriodService";
import { EmployeeGroupItem } from "../../models/employeeGroup/employeeGroupItem";
import { SalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { Chip, Stack, Typography } from "@mui/material";
import { Base } from "../../framework/base";
import { useSelectPaidWorkHoursAmountByDate } from "../../hooks/workTime/useSelectWorkHours";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { useTranslation } from "react-i18next";

interface Props {
    allEmployeeItems: EmployeeListItemDto[];
    selectedSalaryPeriodItem: SalaryPeriodItem;
    setSelectedSalaryPeriodItem: React.Dispatch<
        React.SetStateAction<SalaryPeriodItem>
    >;
}

export const WorkHoursPerSalaryPeriod: React.FC<Props> = ({
    allEmployeeItems,
    selectedSalaryPeriodItem,
    setSelectedSalaryPeriodItem,
}) => {
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const [allEmployeeGroupItems, setAllEmployeeGroupItems] =
        useState<EmployeeGroupItem[]>();
    const [allSalaryPeriodItems, setAllSalaryPeriodItems] =
        useState<SalaryPeriodItem[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const salaryPeriodMinutes = useSelectPaidWorkHoursAmountByDate(
        employeeId,
        selectedSalaryPeriodItem?.startDate,
        selectedSalaryPeriodItem?.endDate
    );

    useEffect(() => {
        setLoading(true);
        employeeGroupService
            .getEmployeeGroupItems(1000, 1, "", "name", true)
            .then((data) => {
                setAllEmployeeGroupItems(data.items);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        if (allEmployeeItems && allEmployeeGroupItems) {
            const selectedEmployee = allEmployeeItems.find(
                (i) => i.id === employeeId
            );
            const selectedEmployeeGroup = allEmployeeGroupItems.find(
                (i) => i.id === selectedEmployee?.salaryGroupId
            );

            if (selectedEmployeeGroup) {
                void salaryPeriodService
                    .getSalaryPeriodItems(
                        selectedEmployeeGroup.salaryPeriodTypeId
                    )
                    .then((data) => {
                        setAllSalaryPeriodItems(data);
                    });
            }
        }
    }, [allEmployeeItems, allEmployeeGroupItems, employeeId]);

    useEffect(() => {
        if (allSalaryPeriodItems && allSalaryPeriodItems.length > 0) {
            const selectedSalaryPeriod = allSalaryPeriodItems.find(
                (i) => i.startDate <= date && i.endDate >= date
            );
            setSelectedSalaryPeriodItem(selectedSalaryPeriod);
        }
    }, [allSalaryPeriodItems, employeeId, date]);

    if (loading || !selectedSalaryPeriodItem) {
        return null;
    }
    return (
        <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
                {t("salaryPeriod")}:{" "}
                {Base.dayjsToDateStrShort(selectedSalaryPeriodItem.startDate)}-
                {Base.dayjsToDateStrShort(selectedSalaryPeriodItem.endDate)}
            </Typography>
            <Chip
                color="green"
                label={`${AppUtils.getDurationStrByDurationMinShort(
                    salaryPeriodMinutes,
                    true
                )} ${t("units.AbrHours")}`}
            />
        </Stack>
    );
};
