// OwnerSettingsSalaryRowTypeDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as salaryRowTypeService from "../../services/salaryRowTypeService";
import { PropertyDialog } from "../framework/dialog";
import { SaveData } from "../../framework/saveData";
import { ISalaryRowTypeEdit } from "../../models/salary/salaryRowTypeEdit";
import { AppUtils } from "../../models/common/appUtils";
import { ConfirmationDialogResult } from "../../models/common/enums";
import { CodeTitle } from "../../models/common/codeTitle";
import { t } from "i18next";
import { AutocompleteOption, AutocompleteWrapper } from "../framework/muiAutocomplete";

// OwnerSettingsSalaryRowTypeDialog
// ***********************************************************************************************************************
interface OwnerSettingsSalaryRowTypeDialogProp {
    classes?: string;
    edit: ISalaryRowTypeEdit;
    onOk: () => void;
    onCancel: () => void;
    hourBookingCategories: CodeTitle[];
}

interface OwnerSettingsSalaryRowTypeDialogState {
    code: string;
    name: string;
    salaryRowTypeKindId: string;
    measureUnit: string;
    hourBookingCategories: string[];
}

export class OwnerSettingsSalaryRowTypeDialog extends React.Component<OwnerSettingsSalaryRowTypeDialogProp, OwnerSettingsSalaryRowTypeDialogState> {
    private orgStateHash = "";
    private measureUnit = ["t", "p", "kpl"];
    constructor(props: OwnerSettingsSalaryRowTypeDialogProp) {
        super(props);
        const salaryRowType = props.edit.salaryRowType;
        const initialSalaryRowTypeKindId = salaryRowType.salaryRowTypeKindId ?
            salaryRowType.salaryRowTypeKindId :
            (props.edit.salaryRowTypeKind.find(i => i.name === Translations.HourBookings)?.id || "");
        const initialMeasureUnit = salaryRowType.measureUnit ? salaryRowType.measureUnit : (this.measureUnit[0] || "");

        this.state = {
            code: salaryRowType.code,
            name: salaryRowType.name,
            salaryRowTypeKindId: initialSalaryRowTypeKindId,
            measureUnit: initialMeasureUnit,
            hourBookingCategories: salaryRowType.hourBookingCategories,
        };
        const saveData = OwnerSettingsSalaryRowTypeDialog.getSaveDataFromState(props, this.state);
        this.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "Nm") {
            this.setState({ name: value });
        } else if (name === "code") {
            this.setState({ code: value });
        }else if (name === "salaryRowTypeKindId") {
            this.setState({ salaryRowTypeKindId: value });
        }else if (name === "measureUnit") {
            this.setState({ measureUnit: value });
        }
    };
    // #endregion General

    handleHourBookingCategoryChange = (val: AutocompleteOption[]) => {
        this.setState({ hourBookingCategories: val.map(v => v.id) });
    };

    private checkErrors = (): Promise<boolean> => {
        const state = this.state;
        return new Promise<boolean>((resolve) => {
            //Add error checking in here
            if (!state.name) {
                AppUtils.showErrorMessage(Translations.NameMustBeDefined);
                return resolve(false);
            }else if (!state.code) {
                AppUtils.showErrorMessage(Translations.CodeMustBeDefined);
                return resolve(false);
            }else if (!state.salaryRowTypeKindId) {
                AppUtils.showErrorMessage(Translations.SalaryRowTypeKindIdMustBeDefined);
                return resolve(false);
            }else if (!state.measureUnit) {
                AppUtils.showErrorMessage(Translations.MeasureUnitMustBeDefined);
                return resolve(false);
            }
            return resolve(true);
        });
    };

    private getWarningMessage = (): Promise<string> => {
        return new Promise<string>((resolve) => {
            //let warnings = "";
            //Add warning checking in here
            //if (!state.someField) {
            //    warnings = warnings + Translations.SomeFieldIsNotDefined + Base.lf;
            //}
            //return resolve(warnings);
            return resolve("");
        });
    };

    private static getSaveDataFromState = (props: OwnerSettingsSalaryRowTypeDialogProp, state: OwnerSettingsSalaryRowTypeDialogState): SaveData => {
        const data = new SaveData();
        const salaryRowType = props.edit.salaryRowType;

        // Common - Data that does not change
        data.append("id", salaryRowType.id);
        data.append("rowId", salaryRowType.rowId);
        // General - Data that changes
        data.append("code", state.code);
        data.append("name", state.name);
        data.append("salaryRowTypeKindId", state.salaryRowTypeKindId);
        data.append("measureUnit", state.measureUnit);
        data.append("hourBookingCategories", JSON.stringify(state.hourBookingCategories));

        return data;
    };

    saveEditItem = async() => {
        const props = this.props;
        const state = this.state;
        if (!await AppUtils.validate(this.checkErrors, this.getWarningMessage)) return;
        const saveData = OwnerSettingsSalaryRowTypeDialog.getSaveDataFromState(props, state);
        //Call server
        const result = await AppUtils.callService(() => salaryRowTypeService.saveSalaryRowTypeEdit(saveData.formData));
        if (!result) return;
        props.onOk();
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = async() => {
        const props = this.props;
        const state = this.state;
        const cancelResult = await AppUtils.cancel(OwnerSettingsSalaryRowTypeDialog.getSaveDataFromState(props, state).hash, this.orgStateHash);
        if (cancelResult === ConfirmationDialogResult.Cancel) return;
        if (cancelResult === ConfirmationDialogResult.Yes) {
            this.saveEditItem();
        } else {
            props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const edit = props.edit;
        const salaryRowType = edit.salaryRowType;
        const dialogClasses = "settings salaryRowType px600" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.SalaryRowType + " - " + (!salaryRowType.isNew() ? salaryRowType.code + " " + salaryRowType.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="row">
                            <div className="col-2">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Code}</label>
                                    <input type="text" className="form-control" name="code" title={Translations.Code} value={state.code} onChange={this.handleChange}/>
                                </div>
                            </div>
                            <div className="col-10">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.Name}</label>
                                    <input type="text" className="form-control" name="Nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50}/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group mt-2">
                                    <AutocompleteWrapper
                                        label={t("hourBooking.category")}
                                        value={state.hourBookingCategories || []}
                                        multiple
                                        fullWidth
                                        size="small"
                                        options={props.hourBookingCategories?.map((c) =>
                                            ({ title: c.title, id: c.code })
                                        )}
                                        onChange={this.handleHourBookingCategoryChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.SalaryRowTypeKind}</label>
                                    <select className="form-control" name="salaryRowTypeKindId" title={Translations.SalaryRowTypeKind} value={state.salaryRowTypeKindId} onChange={this.handleChange} required aria-required={"true"}>
                                        {edit.salaryRowTypeKind
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((salaryRowTypeKind) =>
                                                <option key={salaryRowTypeKind.id} value={salaryRowTypeKind.id}>{salaryRowTypeKind.name}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group required">
                                    <label className="control-label smallFont">{Translations.MeasureUnit}</label>
                                    <select className="form-control" name="measureUnit" title={Translations.MeasureUnit} value={state.measureUnit} onChange={this.handleChange} required aria-required={"true"}>
                                        {this.measureUnit.map((measureUnit) =>
                                            <option key={measureUnit} value={measureUnit}>{measureUnit}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick }
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}
