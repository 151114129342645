import React from "react";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";

interface DateListItemProps {
  date: string;
}

const DateListItem: React.FC<DateListItemProps> = ({ date }) => {
    const theme = useTheme();

    return (
        <ListItem
            sx={{
                backgroundColor: "#E5E5E8",
                color: "#384045",
                fontWeight: 600,
                fontSize: "14px",
                display: "block",
                width: "100%",
                paddingRight: theme.spacing(6),
                textAlign: "center",
            }}
        >
            {date}
        </ListItem>
    );
};

export default DateListItem;