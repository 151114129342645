import React, { useEffect, useMemo } from "react";
import { formatTime } from "../workTimeBeta/workTimeDetailsList";
import CircleIcon from "@mui/icons-material/Circle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getWorkTimeTypeColorClass } from "../../models/workShitTimeSlot/workTimeType";
import { Base } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import DateListItem from "./DateListItem";
import { SidebarProps, WorkShiftData } from "../../models/monitorView/monitorViewTypes";

export const MonitorViewWorkShiftSidebar = ({
    data,
    selectedId,
    setSelectedId,
    setHoveredWorkShiftTimeSlotId,
    setHeader,
}: SidebarProps<WorkShiftData>) => {
    const workShift = data?.[0].workShift;

    if(!workShift) return (
        <Alert variant="standard" severity="info">
            {"Ei rivejä"}
        </Alert>
    );

    const { workShiftTimeSlots, effectiveDate } = workShift;
    const employeeName = useMemo(() => workShiftTimeSlots[0]?.employeeName, [workShiftTimeSlots]);
    const date = useMemo(() => Base.dayjsToDateStr(effectiveDate), [effectiveDate]);

    useEffect(() => {
        setHeader(employeeName);
    }, []);

    return (
        <List dense disablePadding>
            <DateListItem date={date} />
            {workShiftTimeSlots
                ?.sort((a, b) =>
                    Base.dayjsCompare(a.startDate, b.startDate)
                )
                .map((s, index) => (
                    <React.Fragment key={s.id}>
                        {index > 0 && workShiftTimeSlots[index - 1].vehicleId !== s.vehicleId && (
                            <Divider sx={{ borderWidth: "2px" }}/>
                        )}
                        <WorkTimeListItem
                            key={s.id}
                            slot={s}
                            isSelected={s.id === selectedId}
                            onSelect={setSelectedId}
                            onHover={setHoveredWorkShiftTimeSlotId}
                        />
                    </React.Fragment>
                ))}
        </List>
    );
};

interface WorkTimeListItemProps {
    slot: WorkShiftTimeSlotItem;
    isSelected: boolean;
    onSelect: (id: string) => void;
    onHover: (id: string) => void;
}

const WorkTimeListItem = ({
    slot,
    isSelected,
    onSelect,
    onHover,
}: WorkTimeListItemProps) => {
    const { id, startDate, endDate, workTimeTypeName, workTimeTypeType, vehicleRegisterNumber } = slot;
    const { t } = useTranslation();

    const timeLabel = useMemo(
        () =>
            `${formatTime(startDate, Base.dayjsToJsonDate())} - ${
                endDate ? formatTime(endDate, Base.dayjsToJsonDate()) : ""
            }`,
        [startDate, endDate]
    );

    return (
        <ListItem
            disablePadding
            secondaryAction={new WorkShiftTimeSlotItem(slot).getDurationStr()}
        >
            <ListItemButton
                selected={isSelected}
                onClick={() => onSelect(id)}
                onMouseEnter={() => onHover(id)}
                onMouseLeave={() => onHover(null)}
            >
                <ListItemIcon>
                    <CircleIcon
                        className={`text-${getWorkTimeTypeColorClass(
                            workTimeTypeType
                        )}`}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={
                        workTimeTypeName ?? t("workTimeType.noWorkTimeType")
                    }
                    secondary={
                        <span>
                            <span>{timeLabel}</span><br/>
                            <span>{vehicleRegisterNumber}</span>
                        </span>
                        }
                    sx={{ mr: 2 }}
                />
            </ListItemButton>
        </ListItem>
    );
};
