import React from "react";
import { IIdTitle } from "../../models/common/idTitle";
import { SettingsCostCenterDialog } from "./settingsCostCenterDailog";
import { ICostCenterEdit } from "../../models/costCenter/costCenterEdit";
import { EditDialogProps, EditableList } from "../framework/editableList";
import { Translations } from "../../models/translations";
import {
    getCostCenterEdit,
    getCostCenterIdTitles,
} from "../../services/costCenterService";

interface SettingsCustomerCostCenterListProps {
    customerId: string;
    costCenters: IIdTitle[];
    onSave: (id: string, title: string) => void;
    onRemove: (id: string) => void;
}

export const SettingsCustomerCostCenterList = ({
    customerId,
    costCenters,
    onSave,
    onRemove,
}: SettingsCustomerCostCenterListProps) => {
    return (
        <EditableList<ICostCenterEdit>
            title={Translations.CostCenters}
            addDialogTitle={Translations.SelectCostCenter}
            targetId={customerId}
            selectedIdTitles={costCenters}
            fetchAddDialogOptions={getCostCenterIdTitles}
            fetchEditItem={getCostCenterEdit}
            onSave={onSave}
            onRemove={onRemove}
            editDialog={EditDialog}
        />
    );
};

const EditDialog = ({
    editItem,
    onSave,
    onCancel,
}: EditDialogProps<ICostCenterEdit>) => (
    <SettingsCostCenterDialog
        editItem={editItem}
        onOk={onSave}
        onCancel={onCancel}
        disableCustomerEdit
    />
);
