import React, { useEffect, useMemo, useState } from "react";
import {  useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import * as workTimeBetaService from "../../services/workTimeBetaService";
import * as workOrderService from "../../services/workOrderService";
import * as costCenterService from "../../services/costCenterService";
import * as vehicleService from "../../services/vehicleService";
import * as store from "../../framework/customStore";
import * as timeZoneService from "../../services/timeZoneService";
import Grid2 from "@mui/material/Unstable_Grid2";
import MuiRadioGroup from "../framework/muiRadioGroup";
import { Translations } from "../../models/translations";
import MuiAutocomplete, { IMuiSelectOption } from "../framework/muiAutocomplete2";
import { WorkOrderCategory } from "../../models/common/enums";
import { handleApiError } from "../../models/store/storeEffects";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";
import { Button, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { SubmitButton } from "../framework/form";
import { useForm } from "../../hooks";
import {
    HorizontalRule,
    CalendarMonth as CalendarMonthIcon,
} from "@mui/icons-material";
import { Base, maxBy } from "../../framework/base";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { MuiPortal } from "../framework/muiPortal";
import { AppUtils } from "../../models/common/appUtils";
import { CodeTitle } from "../../models/common/codeTitle";
import { WorkTimeSaveData } from "../../models/workShitTimeSlot/workTimeSaveData";
import { setEditId, workShiftTimeSlotReceived } from "../../store/workShiftTimeSlotSlice";
import { OwnerParameters } from "../../models/owner/ownerParameters";
import dayjs from "dayjs";
import { OverlappingSaveDialog } from "./overlappingSaveDialog";
import { usePrompt } from "../../hooks/usePrompt";
import { useTranslation } from "react-i18next";
import { showErrorMessage } from "../../models/store/storeActions";
import { HourBookingCategory } from "../../models/workTime/workHoursItem";
import { Tab } from "./workTimeDetailsFormTab";
import { ISalaryRowType } from "../../models/workShitTimeSlot/salaryRowType";

export enum TaskType {
    Order = "ORDER",
    CostCenter = "COST_CENTER",
}

export interface IWorkTimeFormItem {
    taskType: TaskType;
    workOrder: IMuiSelectOption;
    costCenter: IMuiSelectOption;
    startDate: string | null;
    endDate: string | null;
    description: string;
    vehicle: IMuiSelectOption;
    workTimeTypeId: IMuiSelectOption;
    salaryRowTypeId: IMuiSelectOption;
    timeZoneName: IMuiSelectOption;
}

interface IWorkTimeDetailsFormProps {
    resetFormKey: number;
    editItem: WorkShiftTimeSlotItem;
    workShiftTimeSlotItems: WorkShiftTimeSlotItem[];
    afterSave: () => void;
    salaryRowTypeCategories: HourBookingCategory[];
    salaryRowTypes: ISalaryRowType[];
}

const initEmptyWorkTime = (
    date: string,
    timeZones: CodeTitle[],
    previous?: WorkShiftTimeSlotItem,
): IWorkTimeFormItem => {
    const currentDate = Base.dayjsToJsonDate();
    const tz = previous?.timeZoneName || Base.timeZoneName();
    const timeZoneLabel = timeZones.find(t => t.code === tz)?.title;

    const startDate = previous?.endDate
        ? Base.dayjsToMinuteAccuracy(dayjs(previous.endDate).tz(tz))
        : date === currentDate ? Base.dayjsToMinuteAccuracy() : Base.dayjsToMinuteAccuracy(new Date(date).setHours(8));

    return {
        taskType: TaskType.CostCenter,
        workOrder: null,
        costCenter: null,
        startDate: Base.dayjsToJsonDateTime(startDate),
        endDate: Base.dayjsToJsonDateTime(startDate),
        description: "",
        vehicle: null,
        workTimeTypeId: null,
        salaryRowTypeId: null,
        timeZoneName: timeZoneLabel ? {
            value: tz,
            label: timeZones.find(t => t.code === tz)?.title
        } : null,
    };
};

const initWorkTime = (item: WorkShiftTimeSlotItem): IWorkTimeFormItem => {
    return {
        taskType: item.workOrderId
            ? TaskType.Order
            : TaskType.CostCenter,
        workOrder: item.workOrderId ? {
            value: item.workOrderId,
            label: `${item.workOrderNumber} ${item.workOrderName} - ${item.workOrderCustomerName}`
        } : null,
        costCenter: item.costCenterId ? {
            value: item.costCenterId,
            label: item.costCenterName
        } : null,
        startDate: item.startDate ? Base.dateStrToOriginalTimezoneJsonDateTime(item.startDate) : null,
        endDate: item.endDate ? Base.dateStrToOriginalTimezoneJsonDateTime(item.endDate) : null,
        description: item.comment || "",
        vehicle: item.vehicleId ? {
            value: item.vehicleId,
            label: `${item.vehicleRegisterNumber}, ${item.vehicleBrand}`
        } : null,
        workTimeTypeId: item.workTimeTypeId ? {
            value: item.workTimeTypeId,
            label: item.workTimeTypeName
        } : null,
        salaryRowTypeId: item.salaryRowTypeId ? {
            value: item.salaryRowTypeId,
            label: item.salaryRowTypeName
        } : null,
        timeZoneName: item.timeZoneName ? {
            value: item.timeZoneName,
            label: item.timeZoneDisplayName
        } : null,
    };
};

const DAYJS_PARSE_FORMAT = "D.M.YYYY H.mm";

export function WorkTimeDetailsForm({
    resetFormKey,
    editItem,
    workShiftTimeSlotItems,
    afterSave,
    salaryRowTypeCategories,
    salaryRowTypes,
}: IWorkTimeDetailsFormProps) {
    const { employeeId, date, tab } = useParams<IWorkTimeDetailsPage>();
    const [workTime, setWorkTime] = useState<IWorkTimeFormItem>();
    const [edited, setEdited] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [startFieldValue, setStartFieldValue] = useState<string>();
    const [endFieldValue, setEndFieldValue] = useState<string>();
    const theme = useTheme();
    const [timeZones, setTimeZones] = useState<CodeTitle[]>();
    const [openOverlappingDialog, setOpenOverlappingDialog] = useState(false);
    const workTimeTypes = store.useAppSelector(state => state.workShiftTimeSlot.workTimeTypes);
    const isCustomType = tab === Tab.Custom;

    const salaryRowTypeOptions = useMemo(
        () =>
            salaryRowTypes
                ?.filter((t) =>
                    t.hourBookingCategories.some((c) =>
                        salaryRowTypeCategories.includes(parseInt(c))
                    )
                )
                .map((t) => ({
                    value: t.id,
                    label: t.name,
                })),
        [salaryRowTypes, salaryRowTypeCategories]
    );

    const { t } = useTranslation();

    const previousWorkShiftTimeSlot = useMemo(() => maxBy(workShiftTimeSlotItems, (w) => w.endDate), [workShiftTimeSlotItems]);

    const onSuccess = (result: WorkShiftTimeSlotItem) => {
        store.customStore.dispatch(workShiftTimeSlotReceived(result));
        clearForm();
        afterSave();
    };

    const afterSaveForm = () => {

    };

    const getTimeZones = () => {
        timeZoneService.getTimeZones(null).then(
            (result) => {
                setTimeZones(result);
            },
            (error) => {
                handleApiError(error, store.customStore.dispatch);
            }
        );
    };

    useEffect(() => {
        if (isLoading) {
            getTimeZones();
        }
    }, [isLoading]);

    useEffect(() => {
        clearForm();
    }, [resetFormKey]);

    useEffect(() => {
        if (
            date &&
            employeeId &&
            timeZones &&
            salaryRowTypes &&
            workTimeTypes
        ) {
            setIsLoading(false);

            if (editItem?.employeeId === employeeId) {
                setEdited(false);
                setWorkTime(initWorkTime(editItem));
            } else {
                clearForm();
            }
        }
    }, [
        editItem,
        date,
        workTimeTypes,
        salaryRowTypes,
        timeZones,
        previousWorkShiftTimeSlot,
        employeeId,
    ]);

    const {
        workOrder,
        costCenter,
        startDate,
        endDate,
        taskType,
        description,
        vehicle,
        workTimeTypeId,
        salaryRowTypeId,
        timeZoneName,
    } = workTime || {};
    const submitDisabled =
        (taskType === TaskType.Order &&
            Base.isNullOrUndefined(workOrder?.value)) ||
        Base.isNullOrUndefined(startDate) ||
        Base.isNullOrUndefined(endDate) ||
        (startDate && endDate && Base.isBefore(endDate, startDate)) ||
        (isCustomType && !salaryRowTypeId);

    const duration = startDate && endDate ? Base.dayjsDiffInMinutes(Base.dayjsToMinuteAccuracy(startDate), Base.dayjsToMinuteAccuracy(endDate)) : "";

    const hours = duration ? AppUtils.getDurationStrByDurationMinShort(
        duration, true
    ) : "";

    useEffect(() => {
        setStartFieldValue(startDate ? Base.dayjsDateToDateTimeStr(startDate) : "");
    }, [startDate]);

    useEffect(() => {
        setEndFieldValue(endDate ? Base.dayjsDateToDateTimeStr(endDate) : "");
    }, [endDate]);

    const onError = (error) => {
        if (error.status === 422) {
            setOpenOverlappingDialog(true);
            return false;
        }
        return true;
    };

    const [formRef, submitting, submitForm] = useForm(
        workTimeBetaService.saveWorkShiftTimeSlot,
        afterSaveForm,
        useMemo(
            () => ({
                id: editItem?.id ?? "",
                rowId: editItem?.rowId ?? "",
                employeeId: employeeId,
                isCustomType,
            }),
            [employeeId, editItem?.id, editItem?.rowId, isCustomType]
        ),
        WorkTimeSaveData,
        Translations.WorkShiftTimeSlotSaveSuccess,
        onSuccess,
        onError
    );

    const workOrderAsyncProps = useMemo(() => ({
        load: ({ input }, callback) => {
            workOrderService.getWorkOrderListItems(100, 1, input, [-1], [], "number", true, [WorkOrderCategory.Work, WorkOrderCategory.Task], [], [""])
                .then(result => {
                    callback(result.items.map(i => ({
                        value: i.id,
                        label: `${i.number} ${i.name} - ${i.customerName}`
                    })));
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                });
        },
    }), []);

    const costCenterAsyncProps = useMemo(() => ({
        onOpen: true,
        load: (callback) => {
            costCenterService.getCostCenterItems(100, 1, "", "name", true, false)
                .then(result => {
                    callback(
                        result.items
                            .map((i) => ({
                                value: i.id,
                                label: i.name,
                                group: i.customerName,
                            }))
                            .sort((a, b) =>
                                // Sort first so internal cost centers are first.
                                Base.stringCompare(a.group, b.group)
                            )
                            .map((i) => ({
                                ...i,
                                // Add label for internal cost centers after sorting.
                                group: i.group || t("costCenter.internal"),
                            }))
                    );
                },
                error => {
                    handleApiError(error, store.customStore.dispatch);
                });
        },
    }), []);

    const vehicleAsyncProps = useMemo(
        () => ({
            onOpen: true,
            load: (callback) => {
                vehicleService.getVehicles().then(
                    (result) => {
                        callback(
                            result.data.map((i) => ({
                                value: i.id,
                                label: `${i.registerNumber}, ${i.brand}`,
                            }))
                        );
                    },
                    (error) => {
                        handleApiError(error, store.customStore.dispatch);
                    }
                );
            },
        }),
        []
    );

    const clearForm = () => {
        store.customStore.dispatch(setEditId(null));
        setEdited(false);
        if (date && timeZones) {
            setWorkTime(initEmptyWorkTime(date, timeZones, previousWorkShiftTimeSlot));
        }
    };

    const updateWorkTime = (field: string, value: any) => {
        setEdited(true);
        setWorkTime({ ...workTime, [field]: value });
    }

    const handleDateFieldChange = (field: string, value: string) => {
        const date = value ? Base.dayjsParse(value, DAYJS_PARSE_FORMAT) : null;
        // Allow empty value but revert back to old value if invalid date given.
        const newValue = date ? date.isValid() ? Base.dayjsToJsonDateTime(date) : workTime[field] : null;
        updateWorkTime(field, newValue);
    };

    const handleRadioGroupChange = (value: TaskType) => {
        setEdited(true);
        setWorkTime({
            ...workTime,
            taskType: value,
            workOrder: value === TaskType.Order ? workTime.workOrder : null,
            costCenter: value === TaskType.CostCenter ? workTime.costCenter : null,
        });
    };

    const handleSubmit = async () => {
        if (submitDisabled) {
            store.customStore.dispatch(
                showErrorMessage(t("unableToSaveChanges"))
            );
            return false;
        }

        return await submitForm();
    };

    usePrompt(edited, handleSubmit);

    const showWorkTimeTypeSelection = !isCustomType && workTimeTypes?.length > 0;
    const showVehicle = true;
    const showHourbookingType = true;

    const ownerParameters = new OwnerParameters();
    return isLoading ? null : (
        <form ref={formRef}>
            <Typography variant="h6" mb={1}>
                {editItem ? Translations.Edit : Translations.AddNew}
            </Typography>
            <Grid2 container spacing={2}>
                <Grid2
                    container
                    spacing={2}
                    columns={24}
                    justifyContent="left"
                    maxWidth={1200}
                >
                    {ownerParameters.getOwnerHasWorkOrdersEnabled() && (
                        <Grid2 xs={24}>
                            <MuiRadioGroup
                                options={Object.keys(TaskType).map((k) => ({
                                    value: TaskType[k],
                                    label: Translations[k],
                                }))}
                                value={taskType}
                                onChange={(val) =>
                                    handleRadioGroupChange(val)
                                }
                                required
                                row
                            />
                        </Grid2>
                    )}

                    <Grid2 container xs={24} xl={12}>
                        {taskType === TaskType.Order ? (
                            <Grid2 xs={24} maxWidth={600}>
                                <MuiAutocomplete
                                    key={TaskType.Order}
                                    label={Translations.WorkOrder}
                                    async={workOrderAsyncProps}
                                    name="workOrderId"
                                    value={workOrder}
                                    onChange={(val) =>
                                        updateWorkTime("workOrder", val)
                                    }
                                    required
                                    autocompleteProps={{
                                        sx: { backgroundColor: "white" },
                                    }}
                                />
                            </Grid2>
                        ) : taskType === TaskType.CostCenter ? (
                            <Grid2 xs={24} maxWidth={600}>
                                <MuiAutocomplete
                                    key={TaskType.CostCenter}
                                    label={Translations.CostCenter}
                                    async={costCenterAsyncProps}
                                    name="costCenterId"
                                    value={costCenter}
                                    onChange={(val) =>
                                        updateWorkTime("costCenter", val)
                                    }
                                    autocompleteProps={{
                                        sx: { backgroundColor: "white" },
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        {showVehicle ? (
                            <Grid2
                                xs={24}
                                xl={showWorkTimeTypeSelection ? 8 : 12}
                                maxWidth={600}
                            >
                                <MuiAutocomplete
                                    label={Translations.Vehicle}
                                    async={vehicleAsyncProps}
                                    name="vehicleId"
                                    value={vehicle}
                                    onChange={(val) =>
                                        updateWorkTime("vehicle", val)
                                    }
                                    autocompleteProps={{
                                        sx: { backgroundColor: "white" },
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        {showHourbookingType ? (
                            <Grid2
                                xs={24}
                                xl={showWorkTimeTypeSelection ? 8 : 12}
                                maxWidth={600}
                            >
                                <MuiAutocomplete
                                    label={Translations.SalaryRowType}
                                    name="salaryRowTypeId"
                                    value={salaryRowTypeId}
                                    options={salaryRowTypeOptions}
                                    onChange={(val) =>
                                        updateWorkTime(
                                            "salaryRowTypeId",
                                            val
                                        )
                                    }
                                    autocompleteProps={{
                                        sx: { backgroundColor: "white" },
                                    }}
                                    required={isCustomType}
                                />
                            </Grid2>
                        ) : null}
                        {showWorkTimeTypeSelection ? (
                            <Grid2 xs={24} xl={8} maxWidth={600}>
                                <MuiAutocomplete
                                    label={Translations.WorkTimeType}
                                    name="workTimeTypeId"
                                    value={workTimeTypeId}
                                    options={workTimeTypes?.map((t) => ({
                                        value: t.workTimeTypeId,
                                        label: t.name,
                                    }))}
                                    onChange={(val) =>
                                        updateWorkTime(
                                            "workTimeTypeId",
                                            val
                                        )
                                    }
                                    autocompleteProps={{
                                        sx: { backgroundColor: "white" },
                                    }}
                                />
                            </Grid2>
                        ) : null}
                        {/* </Grid2> */}
                    </Grid2>
                    <Grid2 container xs={24} xl={12} maxWidth={616}>
                        <Grid2 xs={24}>
                            <MuiAutocomplete
                                label={Translations.TimeZone}
                                name="timeZoneName"
                                value={timeZoneName}
                                onChange={(val) =>
                                    updateWorkTime("timeZoneName", val)
                                }
                                options={timeZones?.map((t) => ({
                                    value: t.code,
                                    label: t.title,
                                }))}
                                required
                                autocompleteProps={{
                                    sx: { backgroundColor: "white" },
                                    disableClearable: true,
                                }}
                            />
                        </Grid2>
                        <Grid2 container xs={24}>
                            <Grid2 xs={23} lg={9}>
                                <TextField
                                    label={Translations.StartTime}
                                    sx={{ backgroundColor: "white" }}
                                    required
                                    value={startFieldValue}
                                    onChange={(e) =>
                                        setStartFieldValue(e.target.value)
                                    }
                                    onBlur={(e) => {
                                        handleDateFieldChange(
                                            "startDate",
                                            e.target.value
                                        );
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <DatePicker
                                                    dateFormat="dd.MM.yyyy H.mm"
                                                    locale={fi} // TODO: get from appConfig
                                                    openToDate={
                                                        startDate
                                                            ? new Date(
                                                                    startDate
                                                                )
                                                            : new Date(date)
                                                    }
                                                    selected={
                                                        startDate
                                                            ? new Date(
                                                                    startDate
                                                                )
                                                            : null
                                                    }
                                                    onChange={(val) =>
                                                        updateWorkTime(
                                                            "startDate",
                                                            Base.dayjsToJsonDateTime(
                                                                val
                                                            )
                                                        )
                                                    }
                                                    showWeekNumbers
                                                    showTimeSelect
                                                    timeCaption={
                                                        Translations.Time
                                                    }
                                                    popperContainer={
                                                        MuiPortal
                                                    }
                                                    customInput={
                                                        <IconButton>
                                                            <CalendarMonthIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <input
                                    type="hidden"
                                    name="startDate"
                                    value={startDate || ""}
                                />
                            </Grid2>
                            <Grid2
                                xs={false}
                                lg={2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <HorizontalRule />
                            </Grid2>
                            <Grid2 xs={23} lg={9}>
                                <TextField
                                    label={Translations.EndTime}
                                    error={Base.isBefore(
                                        endDate,
                                        startDate
                                    )}
                                    sx={{ backgroundColor: "white" }}
                                    required
                                    value={endFieldValue}
                                    onChange={(e) =>
                                        setEndFieldValue(e.target.value)
                                    }
                                    onBlur={(e) =>
                                        handleDateFieldChange(
                                            "endDate",
                                            e.target.value
                                        )
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <DatePicker
                                                    dateFormat="dd.MM.yyyy H.mm"
                                                    locale={fi} // TODO: get from appConfig
                                                    selected={
                                                        endDate
                                                            ? new Date(
                                                                    endDate
                                                                )
                                                            : null
                                                    }
                                                    openToDate={
                                                        endDate
                                                            ? new Date(
                                                                    endDate
                                                                )
                                                            : new Date(date)
                                                    }
                                                    onChange={(val) =>
                                                        updateWorkTime(
                                                            "endDate",
                                                            Base.dayjsToJsonDateTime(
                                                                val
                                                            )
                                                        )
                                                    }
                                                    showWeekNumbers
                                                    showTimeSelect
                                                    timeCaption={
                                                        Translations.Time
                                                    }
                                                    popperContainer={
                                                        MuiPortal
                                                    }
                                                    customInput={
                                                        <IconButton>
                                                            <CalendarMonthIcon />
                                                        </IconButton>
                                                    }
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <input
                                    type="hidden"
                                    name="endDate"
                                    value={endDate || ""}
                                />
                            </Grid2>
                            <Grid2 xs={12} lg={4} maxWidth={104}>
                                <TextField
                                    label={`${Translations.WorkTime} (${Translations.AbrHours})`}
                                    value={hours}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    sx={{ backgroundColor: "white" }}
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2
                        xs={24}
                        maxWidth={{
                            xs: 600,
                            xl: 1200,
                        }}
                    >
                        <TextField
                            key={editItem?.id}
                            label={Translations.Description}
                            name="comment"
                            value={description}
                            onChange={(e) =>
                                updateWorkTime(
                                    "description",
                                    e.target.value
                                )
                            }
                            multiline
                            minRows={2}
                            fullWidth
                            sx={{ backgroundColor: "white" }}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 xs={12}>
                    <SubmitButton
                        submitting={submitting}
                        color="secondary"
                        disabled={submitDisabled}
                        sx={{
                            float: "right",
                            color: theme.palette.common.white,
                        }}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{
                            float: "right",
                            marginRight: "1rem",
                        }}
                        onClick={clearForm}
                    >
                        {editItem
                            ? Translations.Cancel
                            : Translations.Clear}
                    </Button>
                </Grid2>
                <Grid2>
                    <OverlappingSaveDialog
                        open={openOverlappingDialog}
                        onClose={() => setOpenOverlappingDialog(false)}
                        workTime={workTime}
                        clearForm={clearForm}
                        afterSave={afterSave}
                        editItem={editItem}
                    />
                </Grid2>
            </Grid2>
        </form>
    );
}
