import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import { SectionHeader } from "../layout/sectionHeader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { MonitorViewVehiclesSidebar } from "./monitorViewVehiclesSidebar";
import { MonitorViewWorkShiftSidebar } from "./monitorViewWorkShiftSidebar";
import { MonitorViewEmployeesSidebar } from "./monitorViewEmployeesSidebar";

import { MonitorViewSidebarProps } from "../../models/monitorView/monitorViewTypes";

export const MonitorViewSidebar = ({
    loading = false,
    workShiftSidebarProps,
    vehicleSidebarProps,
    employeeSidebarProps,
    activeEmployeesAndVehicles
}: MonitorViewSidebarProps) => {
    const [tab, setTab] = useState(0);
    const [header, setHeader] = useState("");
    const [isWorkShiftView, setIsWorkShiftView] = useState(false);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const { data, buttons } = workShiftSidebarProps;
    const { activeVehicles, activeEmployees } = activeEmployeesAndVehicles;
    const workShift = data?.[0]?.workShift;

    useEffect(() => {
        setIsWorkShiftView(!!workShift);
    }, [workShift]);

    return (
        <Box minWidth={375} height="100%" display="flex" flexDirection="column">
            <SectionHeader position="sticky">
                <>
                    { isWorkShiftView ? (
                        <Box sx={{ margin: "0 auto" }}>
                            <Tabs
                                value={0}
                            >
                                <Tab label={header} icon={<PersonIcon sx={{ color: "#FFFFFF" }} />} />
                            </Tabs>
                        </Box>
                    ) : (
                        <Box sx={{ margin: "0 auto" }}>
                            <Tabs
                                onChange={handleChange}
                                value={tab}
                            >
                                <Tab label={`Kalusto (${activeVehicles})`} icon={<LocalShippingIcon sx={{ color: "#FFFFFF" }} />} />
                                <Tab label={`Työntekijät (${activeEmployees})`} icon={<PersonIcon />} sx={{ color: "#FFFFFF" }} />
                            </Tabs>
                        </Box>
                    )}

                    {isWorkShiftView && buttons?.map((b) => (
                        <IconButton
                            key={b.key}
                            color="inherit"
                            onClick={b.onClick}
                        >
                            {b.icon}
                        </IconButton>
                    ))}
                </>
            </SectionHeader>
            {loading ? (
                <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    sx={{
                        overflowY: "auto",
                    }}
                >
                    { isWorkShiftView
                        ? (<MonitorViewWorkShiftSidebar {...workShiftSidebarProps} setHeader={setHeader} />)
                        : (tab === 0
                            ? <MonitorViewVehiclesSidebar {...vehicleSidebarProps} />
                            : <MonitorViewEmployeesSidebar {...employeeSidebarProps} />)}
                </Box>
            )}
        </Box>
    );
};