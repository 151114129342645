import { Base } from "../../framework/base";
import { ICustomerEditItem, CustomerEditItem } from "./customerEditItem";
import { IIdTitle, IdTitle } from "../common/idTitle";
import { ICustomerType, CustomerType } from "./customerType";
import { IProductItem, ProductItem } from "../product/productItem";

export interface ICustomerEdit {
    customer: ICustomerEditItem;
    customerTypes: ICustomerType[];
    mapLinkTemplate: string;
    workOrderAcknowledgementTypes: IIdTitle[];
    invoicePeriods: IIdTitle[];
    products: IProductItem[];
    costCenters: IIdTitle[];
}

export class CustomerEdit implements ICustomerEdit {
    customer: CustomerEditItem;
    customerTypes: CustomerType[];
    mapLinkTemplate: string;
    workOrderAcknowledgementTypes: IdTitle[];
    invoicePeriods: IdTitle[];
    products: ProductItem[];
    costCenters: IdTitle[];

    constructor();
    constructor(obj: ICustomerEdit);
    constructor(obj?: any) {
        this.customer = null;
        if (obj && obj.customer) {
            this.customer = new CustomerEditItem(obj.customer);
        }
        this.mapLinkTemplate = obj && obj.mapLinkTemplate || "";
        this.customerTypes = Base.getTypedArray<CustomerType>(!Base.isNullOrUndefined(obj) ? obj.customerTypes : null, CustomerType);
        this.workOrderAcknowledgementTypes = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.workOrderAcknowledgementTypes : null, IdTitle);
        this.invoicePeriods = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.invoicePeriods : null, IdTitle);
        this.products = Base.getTypedArray<ProductItem>(!Base.isNullOrUndefined(obj) ? obj.products : null, ProductItem);
        this.costCenters = Base.getTypedArray<IdTitle>(!Base.isNullOrUndefined(obj) ? obj.costCenters : null, IdTitle);
    }
}