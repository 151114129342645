import React from "react";
import Alert from "@mui/material/Alert";
import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { getWorkTimeTypeColorClass } from "../../models/workShitTimeSlot/workTimeType";
import { MuiSelectableList } from "../framework/muiSelectableList";

import { SidebarProps, EmployeeData } from "../../models/monitorView/monitorViewTypes";

export const MonitorViewEmployeesSidebar = ({
    data,
    selectedId: selectedEmployeeId,
    setSelectedId: setSelectedEmployeeId,
}: SidebarProps<EmployeeData>) => {
    const { t } = useTranslation();

    return (
        data?.length > 0 ? (
            <MuiSelectableList
                selected={selectedEmployeeId}
                items={data?.map((e) => ({
                    id: e.employeeId,
                    icon: (
                        <CircleIcon
                            className={`text-${getWorkTimeTypeColorClass(
                                    e.workTimeTypeType
                                )}`}
                        />
                    ),
                    text: e.employeeName,
                    onSelect: setSelectedEmployeeId,
                }))}
            />
        ) : (
            <Alert variant="standard" severity="info">
                {t("employee.noRows")}
            </Alert>
        )
    );
};
