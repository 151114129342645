//SettingsCostCenterDialog - MODULE
// ***********************************************************************************************************************
import * as React from "react";
import { Translations } from "../../models/translations";
import * as store from "../../framework/customStore";
import * as baseService from "../../services/baseService";
import * as storeActions from "../../models/store/storeActions";
import * as costCenterService from "../../services/costCenterService";
import { PropertyDialog } from "../framework/dialog";
import { Base } from "../../framework/base";
import { SaveData } from "../../framework/saveData";
import { CheckBox } from "../framework/checkbox";
import { ConfirmationDialogType } from "../../models/store/storeTypes";
import { ICostCenterEdit } from "../../models/costCenter/costCenterEdit";
import { t } from "i18next";
import { AutocompleteOption, AutocompleteWrapper } from "../framework/muiAutocomplete";

// settingsCostCenterDialog
// ***********************************************************************************************************************
export interface ISettingsCostCenterDialogProp {
    classes?: string;
    editItem: ICostCenterEdit;
    onOk: (id?: string, name?: string) => void;
    onCancel: () => void;
    disableCustomerEdit?: boolean;
}

export interface ISettingsCostCenterDialogState {
    name: string;
    code: string;
    comment: string;
    activeState: number;
    customerId: string;
}

export class SettingsCostCenterDialog extends React.Component<ISettingsCostCenterDialogProp, ISettingsCostCenterDialogState> {
    private static orgStateHash: string = "";

    constructor(props: ISettingsCostCenterDialogProp) {
        super(props);
        const costCenter = props.editItem.costCenter;
        this.state = {
            name: costCenter.name,
            code: costCenter.code,
            comment: costCenter.comment,
            activeState: costCenter.activeState,
            customerId: costCenter.customerId,
        };
        const saveData = SettingsCostCenterDialog.getSaveDataFromState(props, this.state);
        SettingsCostCenterDialog.orgStateHash = saveData.hash;
    }

    // #region General
    handleChange = (event) => {
        const target = event.target;
        const value: string = target.value;
        const name: string = target.name;
        if (name === "nm") {
            this.setState({ name: value });
        } else if (name === "comment") {
            this.setState({ comment: value });
        } else if (name === "code") {
            this.setState({ code: value });
        }
    };

    handleActiveStateChange = (newActiveState: number) => {
        this.setState({ activeState: newActiveState });
    };

    handleCustomerChange = (customer: AutocompleteOption | null) => {
        this.setState({ customerId: customer?.id ?? "" });
    }

    // #endregion General

    private static validate = (state: ISettingsCostCenterDialogState): boolean => {
        if (!state.name) {
            store.customStore.dispatch(storeActions.showErrorMessage(Translations.NameMustBeDefined));
            return false;
        }
        return true;
    };

    private static getSaveDataFromState = (props: ISettingsCostCenterDialogProp, state: ISettingsCostCenterDialogState): SaveData => {
        const data = new SaveData();
        const costCenter = props.editItem.costCenter;
        // Common
        data.append("id", costCenter.id);
        data.append("rowId", costCenter.rowId);
        // General
        data.append("name", state.name);
        data.append("code", state.code);
        data.append("comment", state.comment);
        data.append("activeState", state.activeState.toString(10));
        data.append("customerId", state.customerId);
        return data;
    };

    saveEditItem = () => {
        const obj = this;
        if (!SettingsCostCenterDialog.validate(this.state)) return;
        const saveData = SettingsCostCenterDialog.getSaveDataFromState(this.props, this.state);
        if (!saveData) return;
        // Call server
        store.customStore.dispatch(storeActions.fetchStart());
        costCenterService.saveCostCenterEdit(saveData.formData)
            .then(success => {
                store.customStore.dispatch(storeActions.showSuccessMessage(success.message));
                obj.props.onOk(success.id, this.state.name);
            })
            .catch(error => {
                store.customStore.dispatch(storeActions.showErrorMessage(baseService.getErrorMessageFromError(error)));
                return null;
            })
            .finally(() => store.customStore.dispatch(storeActions.fetchEnd()));
    };

    handleOkClick = () => {
        this.saveEditItem();
    };

    handleCancelClick = () => {
        const obj = this;
        const saveData = SettingsCostCenterDialog.getSaveDataFromState(this.props, this.state);
        if (!Base.isNullOrUndefined(saveData) && saveData.hash !== SettingsCostCenterDialog.orgStateHash) {
            store.customStore.dispatch(storeActions.setConfirmation(ConfirmationDialogType.Warning, Translations.Warning, Translations.YouHaveNotSavedChangesDoYouWantToSaveChanges,
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.saveEditItem();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                    obj.props.onCancel();
                },
                () => {
                    store.customStore.dispatch(storeActions.clearConfirmation());
                }));
        } else {
            obj.props.onCancel();
        }
    };

    render() {
        const props = this.props;
        const state = this.state;
        const editItem = props.editItem;
        const dialogClasses = "settings costCenter px800" + (props.classes ? " " + props.classes : "");
        return (
            <div>
                <PropertyDialog
                    classes={dialogClasses}
                    title={Translations.CostCenter + " - " + (!editItem.costCenter.isNew() ? editItem.costCenter.number.toString(10) + " " + editItem.costCenter.name : Translations.New)}
                    show={true}
                    body={<div>
                        <div className="">
                            <div>
                                <div>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Number}</label>
                                                <input type="text" className="form-control" name="number" title={Translations.Number} value={editItem.costCenter.isNew() ? Translations.New : editItem.costCenter.number.toString(10)} readOnly={true} disabled={true} />
                                            </div>
                                        </div>
                                        <div className="col-7">
                                            <div className="form-group required">
                                                <label className="control-label smallFont">{Translations.Name}</label>
                                                <input type="text" className="form-control" name="nm" title={Translations.Name} value={state.name} onChange={this.handleChange} maxLength={50} />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label className="control-label smallFont">&nbsp;</label>
                                                <div>
                                                    <CheckBox
                                                        title={Translations.InUse}
                                                        enabled={true}
                                                        checked={state.activeState > 0}
                                                        onCheckboxClickBoolean={(checked: boolean) => { this.handleActiveStateChange(checked ? 1 : 0); }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <div className="form-group">
                                                <label className="control-label smallFont wrap">{t("costCenter.code")}</label>
                                                <input type="text" className="form-control" name="code" title={Translations.Code} value={state.code} onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        {!this.props.disableCustomerEdit ? (
                                            <div className="col-4">
                                               <div className="form-group">
                                                    <label className="control-label smallFont wrap">{Translations.Customer}</label>
                                                    <AutocompleteWrapper size="small" options={editItem.customers} value={state.customerId} onChange={this.handleCustomerChange} />
                                               </div>
                                            </div>
                                        ) : null}
                                        <div className={this.props.disableCustomerEdit ? "col-10" : "col-6"}>
                                            <div className="form-group">
                                                <label className="control-label smallFont">{Translations.Comment}</label>
                                                <textarea className="form-control" name="comment" title={Translations.Comment} value={state.comment} onChange={this.handleChange} maxLength={1000} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    buttons={[
                        { title: Translations.Save, classes: "btn-primary", enabled: true, onClick: this.handleOkClick },
                    ]}
                    onClose={this.handleCancelClick}
                />
            </div>
        );
    }
}