export interface ISalaryRowTypeKindItem {
    id: string;
    code: string;
    name: string;
}

export class SalaryRowTypeKindItem implements ISalaryRowTypeKindItem {
    id: string;
    code: string;
    name: string;

    constructor();
    constructor(obj: ISalaryRowTypeKindItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
    }
}
