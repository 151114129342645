import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Button, IconButton, Typography, useTheme } from "@mui/material";
import { TableDef, TableWrapper } from "../../framework/tableWrapper";
import * as store from "../../../framework/customStore";
import * as storeActions from "../../../models/store/storeActions";
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
import { ConfirmationDialogType } from "../../../models/store/storeTypes";
import { getOrderTypes, deleteOrderType } from "../../../services/orderTypeService";

export function SettingsOrderTypeList() {
    const [items, setItems] = useState([]);
    const dispatch = store.customStore.dispatch;
    const theme = useTheme();
    const history = useHistory();

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = () => {
        getOrderTypes().then(response => setItems(response.data));
    };

    const handleDelete = (orderTypeId, orderTypeName) => {
        dispatch(
            storeActions.setConfirmation(
                ConfirmationDialogType.Warning,
                "Warning",
                `Are you sure you want to delete ${orderTypeName}?`,
                () => {
                    deleteOrderType(orderTypeId).then(() => {
                        fetchItems();
                        dispatch(storeActions.clearConfirmation());
                    });
                }
            )
        );
    };

    const table = useMemo<TableDef<any>>(
        () => ({
            columns: [
                {
                    label: "Tilaustyyppi",
                    accessor: "title",
                },
                {
                    label: "",
                    renderCell: (_, row) => (
                        <Grid2 container justifyContent="flex-end">
                            <IconButton size="small" onClick={() => history.push(`/ordertypes/${row.orderTypeId}`)}>
                                <EditIcon color="primary" />
                            </IconButton>
                            <IconButton size="small" onClick={() => handleDelete(row.orderTypeId, row.title)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Grid2>
                    ),
                },
            ],
        }),
        [items]
    );

    return (
        <Grid2 sx={{ padding: "1rem" }}>
            <Typography variant="h3">Tilaustyypit</Typography>
            <Grid2 xs={12} container spacing={2} alignItems="center" justifyContent="flex-end">
                <Button
                    color="secondary"
                    sx={{ color: theme.palette.common.white }}
                    variant={"contained"}
                    onClick={() => history.push("/ordertypes/new")}
                >
                    <AddIcon /> Lisää uusi
                </Button>
            </Grid2>
            <TableWrapper tableDef={table} data={items} />
        </Grid2>
    );
}