import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    getVehiclePositions,
    getVehicles,
} from "../../services/vehicleService";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import { useFetch } from "../../hooks/useFetch";
import { useInterval } from "usehooks-ts";
import { VehiclePositionInfo } from "../../models/vehicle/vehiclePosition";
import { WorkShiftDto } from "../../models/workShift/workShift";
import { getCurrentWorkShift } from "../../services/workShiftService";
import { handleApiError } from "../../models/store/storeEffects";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { useReload } from "../../hooks";
import { MonitorViewMainLayout } from "./monitorViewMainLayout";
import { fetchEmployees as getEmployees } from "../../store/employeeSlice";

const refreshInterval = 60 * 1000; // 1 minute

export const MonitorViewMain = () => {
    const [positionsLoading, setPositionsLoading] = useState(true);
    const [workShiftLoading, setWorkShiftLoading] = useState(false);

    const [vehicles, setVehicles] = useState<VehicleListItemDto[]>();
    const [positions, setPositions] = useState<VehiclePositionInfo[]>();
    const employees = useAppSelector((state) => state.employees.employees);

    const [selectedVehicleId, setSelectedVehicleId] = useState<string>();
    const [selectedWorkShiftTimeSlotId, setSelectedWorkShiftTimeSlotId] =
        useState<string>();
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();

    const [workShift, setWorkShift] = useState<WorkShiftDto>();
    const [key, reload] = useReload();

    const dispatch = useAppDispatch();
    const loading = positionsLoading || workShiftLoading;

    const employeeId = useMemo(() => {
        if (selectedEmployeeId) {
            return positions?.find((p) =>
                p.employeeWorkTimeTypes.some((e) => e.employeeId === selectedEmployeeId)
            )?.employeeWorkTimeTypes[0]?.employeeId || null;
        }

        if (selectedVehicleId) {
            return positions?.find((p) => p.vehicleId === selectedVehicleId)
                ?.employeeWorkTimeTypes[0]?.employeeId || null;
        }

    }, [positions, selectedVehicleId, selectedEmployeeId]);

    const handleSelect = (
        id: string,
        selectedId: string | null,
        setSelectedId: (id: string | null) => void,
        resetWorkShift: boolean = true) =>
    {
        if (resetWorkShift) {
            setWorkShift(null);
            setSelectedWorkShiftTimeSlotId(null);
        }

        if (id !== selectedId) {
            setSelectedId(id);
        } else {
            setSelectedId(null);
        }
    };

    const onVehicleSelect = (id: string) => {
        handleSelect(id, selectedVehicleId, setSelectedVehicleId);
        setSelectedEmployeeId(null);
    };

    const onWorkShiftTimeSlotSelect = (id: string) => {
        handleSelect(id, selectedWorkShiftTimeSlotId, setSelectedWorkShiftTimeSlotId, false);
    };

    const onEmployeeSelect = (id: string) => {
        handleSelect(id, selectedEmployeeId, setSelectedEmployeeId);
    };

    useFetch(
        (signal) => getVehicles(false, signal),
        {
            onSuccess: (res) => setVehicles(res.data),
            withLoadingIndicator: false,
        },
        []
    );

    useEffect(() => {
        if (!!positions) setPositionsLoading(false);
    }, [positions]);

    useEffect(() => {
        if (!!workShift) setWorkShiftLoading(false);
    }, [workShift]);

    const fetchLocations = useCallback(
        (showLoadingIndicator = false) => {
            if (!selectedVehicleId && vehicles?.length > 0) {
                if (showLoadingIndicator) {
                    setPositionsLoading(true);
                }

                getVehiclePositions(
                    selectedVehicleId
                        ? [selectedVehicleId]
                        : vehicles.map((v) => v.id),
                    true
                )
                    .then((res) => {
                        setPositions(res?.data);
                    })
                    .catch((e) => {
                        if (showLoadingIndicator) {
                            setPositionsLoading(false);
                        }
                        handleApiError(e, dispatch);
                    });
            }
        },
        [vehicles, selectedVehicleId]
    );

    const fetchWorkShift = useCallback(
        (showLoadingIndicator = false) => {
            if (!!employeeId) {
                if (showLoadingIndicator) {
                    setWorkShiftLoading(true);
                }

                getCurrentWorkShift(employeeId, true)
                    .then((res) => setWorkShift(res))
                    .catch((e) => {
                        if (showLoadingIndicator) {
                            setWorkShiftLoading(false);
                        }
                        handleApiError(e, dispatch);
                    });
            }
        },
        [employeeId]
    );

    const fetchEmployees = useCallback(() => {
        if (employees.length === 0) {
            dispatch(getEmployees() as any);
        }
    }, [employees]);

    const onRefresh = (showLoadingIndicator = false) => {
        fetchLocations(showLoadingIndicator);
        fetchWorkShift(showLoadingIndicator);
        reload();
    };

    useEffect(() => {
        fetchLocations();
    }, [fetchLocations]);

    useEffect(() => {
        fetchWorkShift();
    }, [fetchWorkShift]);

    useEffect(() => {
        fetchEmployees();
    }, [fetchEmployees]);

    useInterval(onRefresh, refreshInterval);

    return (
        <MonitorViewMainLayout
            positions={positions}
            vehicles={vehicles}
            loading={loading}
            workShift={workShift}
            selectedVehicleId={selectedVehicleId}
            selectedWorkShiftTimeSlotId={selectedWorkShiftTimeSlotId}
            selectedEmployeeId={selectedEmployeeId}
            reloadKey={key}
            onRefresh={onRefresh}
            setSelectedVehicleId={onVehicleSelect}
            setSelectedWorkShiftTimeSlot={onWorkShiftTimeSlotSelect}
            setSelectedEmployeeId={onEmployeeSelect}
        />
    );
};
