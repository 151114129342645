import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VehicleListItemDto } from "../models/transport/vehicle";
import { EmployeeListItemDto } from "../models/employee/employee";
import { SalaryRowTypeItem } from "../models/salary/salaryRowTypeItem";
import { WorkTimeTypeListItem } from "../models/workTimeType/workTimeTypeListItem";
import { ICostCenterItem } from "../models/costCenter/costCenterItem";
import { Base } from "../framework/base";
import { getVehicles } from "../services/vehicleService";
import { getEmployees } from "../services/employeeServiceNew";
import { getSalaryRowTypeItems } from "../services/salaryRowTypeService";
import { getCostCenterItems } from "../services/costCenterService";
import { getAllWorkTimeTypes } from "../services/workTimeTypeService";
import { ICustomerItem } from "../models/customer/customerItem";
import { getCustomerItems } from "../services/customerService";

export interface Filters {
    timeRange: [string, string];
    selectedVehicles: string[];
    selectedCostCenters: string[];
    selectedEmployees: string[];
    selectedEmployeeGroups: string[];
}

export enum GroupingValue {
    Employee = "employeeId",
    Vehicle = "vehicleId",
    Customer = "customerId",
    CostCenter = "costCenterId",
}

export interface ReportOptionsState {
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    salaryRowTypes: SalaryRowTypeItem[];
    costCenters: ICostCenterItem[];
    customers: ICustomerItem[];
    workTimeTypes: WorkTimeTypeListItem[];
    filters: Filters;
    grouping: string[];
}

export const initialState: ReportOptionsState = {
    vehicles: null,
    employees: null,
    salaryRowTypes: null,
    costCenters: null,
    customers: null,
    workTimeTypes: null,
    filters: {
        timeRange: [
            Base.dayjsToJsonDate(Base.dateStartOf(new Date(), "month")),
            Base.dayjsToJsonDate(Base.dateEndOf(new Date(), "month")),
        ],
        selectedVehicles: [],
        selectedCostCenters: [],
        selectedEmployees: [],
        selectedEmployeeGroups: [],
    },
    grouping: [GroupingValue.Vehicle],
};

export const reportOptionsSlice = createSlice({
    name: "reportOptions",
    initialState,
    reducers: {
        setVehicles: (state, action: PayloadAction<VehicleListItemDto[]>) => {
            state.vehicles = action.payload;
        },
        setEmployees: (state, action: PayloadAction<EmployeeListItemDto[]>) => {
            state.employees = action.payload;
        },
        setSalaryRowTypes: (
            state,
            action: PayloadAction<SalaryRowTypeItem[]>
        ) => {
            state.salaryRowTypes = action.payload;
        },
        setCostCenters: (state, action: PayloadAction<ICostCenterItem[]>) => {
            state.costCenters = action.payload;
        },
        setCustomers: (state, action: PayloadAction<ICustomerItem[]>) => {
            state.customers = action.payload;
        },
        setWorkTimeTypes: (
            state,
            action: PayloadAction<WorkTimeTypeListItem[]>
        ) => {
            state.workTimeTypes = action.payload;
        },
        setTimeRange(state, action: PayloadAction<[string, string]>) {
            state.filters.timeRange = action.payload;
        },
        setSelectedVehicles(state, action: PayloadAction<string[]>) {
            state.filters.selectedVehicles = action.payload;
        },
        setSelectedCostCenters(state, action: PayloadAction<string[]>) {
            state.filters.selectedCostCenters = action.payload;
        },
        setSelectedEmployees(state, action: PayloadAction<string[]>) {
            state.filters.selectedEmployees = action.payload;
        },
        setSelectedEmployeeGroups(state, action: PayloadAction<string[]>) {
            state.filters.selectedEmployeeGroups = action.payload;
        },
        clearFilters: (state) => {
            state.filters = { ...initialState.filters };
        },
        setReportGrouping: (state, action: PayloadAction<string[]>) => {
            state.grouping = action.payload.filter(Boolean);
        },
    },
});

export const {
    setVehicles,
    setEmployees,
    setSalaryRowTypes,
    setCostCenters,
    setCustomers,
    setWorkTimeTypes,
    setTimeRange,
    setSelectedVehicles,
    setSelectedCostCenters,
    setSelectedEmployees,
    setSelectedEmployeeGroups,
    clearFilters,
    setReportGrouping,
} = reportOptionsSlice.actions;

export const fetchReportVehicles = createAsyncThunk(
    "reportOptions/vehicles",
    async (_, thunkApi) => {
        const vehicles = await getVehicles(true, thunkApi.signal);
        thunkApi.dispatch(setVehicles(vehicles?.data));
    }
);

export const fetchReportEmployees = createAsyncThunk(
    "reportOptions/employees",
    async (_, thunkApi) => {
        const employees = await getEmployees(null, thunkApi.signal);
        thunkApi.dispatch(setEmployees(employees?.data));
    }
);

export const fetchReportSalaryRowTypes = createAsyncThunk(
    "reportOptions/salaryRowTypes",
    async (_, thunkApi) => {
        const salaryRowTypes = await getSalaryRowTypeItems(
            1000,
            1,
            "",
            "name",
            true
        );
        thunkApi.dispatch(
            setSalaryRowTypes(
                salaryRowTypes?.items.sort((a, b) =>
                    Base.stringCompare(a.code, b.code)
                )
            )
        );
    }
);

export const fetchReportCostCenters = createAsyncThunk(
    "reportOptions/costCenters",
    async (_, thunkApi) => {
        const costCenters = await getCostCenterItems(
            1000,
            1,
            "",
            "name",
            true,
            true
        );
        thunkApi.dispatch(setCostCenters(costCenters?.items));
    }
);

export const fetchReportCustomers = createAsyncThunk(
    "reportOptions/customers",
    async (_, thunkApi) => {
        const customers = await getCustomerItems(
            1000,
            1,
            "",
            "name",
            true,
            true
        );
        thunkApi.dispatch(setCustomers(customers?.items));
    }
);

export const fetchReportWorkTimeTypes = createAsyncThunk(
    "reportOptions/workTimeTypes",
    async (_, thunkApi) => {
        const workTimeTypes = await getAllWorkTimeTypes(thunkApi.signal);
        thunkApi.dispatch(setWorkTimeTypes(workTimeTypes));
    }
);

export const reportOptionsReducer = reportOptionsSlice.reducer;
