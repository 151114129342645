import React, { useState } from "react";
import { CommandRow } from "./commandRow";
import { IdTitle, IIdTitle } from "../../models/common/idTitle";
import { showError } from "../storage/storageUtils";
import { SelectIdTitleDialog } from "./selectIdTitleDialog";
import { EndlessList, IEndlessList } from "../../models/common/endlessList";
import { Base } from "../../framework/base";

export interface EditDialogProps<T> {
    editItem: T;
    onSave: (id?: string, title?: string) => void;
    onCancel: () => void;
}

interface EditableListProps<T> {
    title: string;
    addDialogTitle: string;
    allowMultipleTargets?: boolean;
    targetId: string;
    selectedIdTitles: IIdTitle[];
    onSave: (id: string, title: string) => void;
    onRemove: (id: string) => void;
    fetchAddDialogOptions: () => Promise<IIdTitle[]>;
    fetchEditItem: (id: string) => Promise<T>;
    editDialog: (props: EditDialogProps<T>) => React.JSX.Element;
}

export function EditableList<T>({
    title,
    allowMultipleTargets = false,
    addDialogTitle,
    targetId,
    selectedIdTitles,
    onSave,
    onRemove,
    fetchAddDialogOptions,
    fetchEditItem,
    editDialog: EditDialog,
}: EditableListProps<T>) {
    const [selectedId, setSelectedId] = useState<string>();
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [addDialogOptions, setAddDialogOptions] = useState<IIdTitle[]>();
    const [editItem, setEditItem] = useState<T>();

    const onItemClick = (id: string) => {
        setSelectedId(id);
    };

    const onItemDoubleClick = (id: string) => {
        handleEdit(id);
    };

    // Fetch dialog options and open add dialog
    const handleAdd = () => {
        fetchAddDialogOptions()
            .then((res) =>
                setAddDialogOptions(
                    res.filter(
                        (r) =>
                            (allowMultipleTargets ||
                                // Filter out options which belong to other target
                                !r.typeId ||
                                r.typeId === targetId) &&
                            // Don't show already selected options
                            !selectedIdTitles.map((c) => c.id).includes(r.id)
                    )
                )
            )
            .catch(showError);

        setShowAddDialog(true);
    };

    // Edit existing item
    const handleEdit = (id: string) => {
        setShowEditDialog(true);
        fetchEdit(id);
    };

    // Add new item
    const handleAddNewItem = () => {
        setShowEditDialog(true);
        fetchEdit(Base.emptyGuid);
    };

    const handleSaveEditDialog = (id: string, title: string) => {
        hideAddDialog(); // Hide add dialog in case new item was created via add dialog.
        onSave(id, title);
        handleCloseEditDialog();
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
        setEditItem(null);
    };

    const handleRemove = () => {
        onRemove(selectedId);
        setSelectedId(null);
    };

    const handleFilterDialogOptions = (_: number, filter: string) => {
        const lowFilter = filter.toLowerCase();
        return new Promise<IEndlessList<IdTitle>>((resolve) => {
            const result = new EndlessList<IdTitle>(null, IdTitle);
            result.items = !lowFilter
                ? addDialogOptions
                : addDialogOptions.filter(
                      (i) => i.title.toLowerCase().indexOf(lowFilter) > -1
                  );
            result.page = 1;
            result.hasMore = false;
            resolve(result);
        });
    };

    const fetchEdit = (id: string) => {
        fetchEditItem(id)
            .then((item) => setEditItem(item))
            .catch(showError);
    };

    const hideAddDialog = () => {
        setShowAddDialog(false);
        setAddDialogOptions(null);
    };

    const handleItemAdded = (id: string, title: string) => {
        onSave(id, title);
        hideAddDialog();
    };

    return (
        <>
            <CommandRow
                title={title}
                selectedId={selectedId}
                handleAdd={handleAdd}
                handleEdit={() => handleEdit(selectedId)}
                handleRemove={handleRemove}
            />
            <div className="listContainer">
                <div className="list">
                    {selectedIdTitles?.map((item) => (
                        <div
                            key={item.id}
                            className={
                                "row line" +
                                (item.id === selectedId ? " selected" : "")
                            }
                            onClick={() => {
                                onItemClick(item.id);
                            }}
                            onDoubleClick={() => {
                                onItemDoubleClick(item.id);
                            }}
                            title={item.title}
                        >
                            <div className="col-12">{item.title}</div>
                        </div>
                    ))}
                </div>
            </div>
            {showAddDialog && !!addDialogOptions ? (
                <SelectIdTitleDialog
                    classes="select px400"
                    title={addDialogTitle}
                    selectedId={null}
                    listbox={false}
                    autoFilter={true}
                    loadIdCodes={handleFilterDialogOptions}
                    onAdd={handleAddNewItem}
                    onOk={handleItemAdded}
                    onCancel={hideAddDialog}
                />
            ) : null}
            {showEditDialog && !!editItem ? (
                <EditDialog
                    editItem={editItem}
                    onSave={handleSaveEditDialog}
                    onCancel={handleCloseEditDialog}
                />
            ) : null}
        </>
    );
}
