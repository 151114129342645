import React from "react";
import Form from "@rjsf/core";
import defaultValidator from "@rjsf/validator-ajv8";

interface DynamicFormProps {
  schema: any;
  uischema: any;
  formValues?: any;
  onSubmit: (formData: any) => void;
  onChange: (formData: any) => void;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ schema, uischema, formValues, onSubmit, onChange }) => {
    const handleSubmit = ({ formValues }: any) => {
        onSubmit(formValues);
    };

    const handleChange = ({ formValues }: any) => {
        onChange(formValues);
    };

    const combinedUiSchema = {
        ...uischema,
        "ui:submitButtonOptions": { norender: true },
    };

    return (
        <Form
            schema={schema}
            uiSchema={combinedUiSchema}
            formData={formValues}
            onSubmit={handleSubmit}
            onChange={handleChange}
            validator={defaultValidator}
        />
    );
};

export default DynamicForm;