import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AppUtils } from "../../models/common/appUtils";
import { SalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { Chip, Stack, Typography } from "@mui/material";
import { useSelectDaybookingFillingHoursAmountByDate, useSelectFillingHoursAmountByDate } from "../../hooks/workTime/useSelectWorkHours";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { useTranslation } from "react-i18next";

interface Props {
    allEmployeeItems: EmployeeListItemDto[];
    selectedSalaryPeriodItem: SalaryPeriodItem;
    salaryRowTypeId: string;
}

export const FillingHoursPerSalaryPeriod: React.FC<Props> = ({
    selectedSalaryPeriodItem,
    salaryRowTypeId
}) => {
    const { employeeId } = useParams<IWorkTimeDetailsPage>();
    const [loading] = useState<boolean>(false);
    const { t } = useTranslation();

    const salaryPeriodMinutes = useSelectFillingHoursAmountByDate(
        employeeId,
        selectedSalaryPeriodItem?.startDate,
        selectedSalaryPeriodItem?.endDate
    );

    const salaryPeriodDaybookingMinutes = useSelectDaybookingFillingHoursAmountByDate(
        employeeId,
        selectedSalaryPeriodItem?.startDate,
        selectedSalaryPeriodItem?.endDate,
        salaryRowTypeId
    )

    if (loading || !selectedSalaryPeriodItem) {
        return null;
    }

    return (
        <Stack direction="row" gap={2} alignItems="center">
            <Typography variant="h4">
                {t("salaryPeriodFillingHours")}
            </Typography>
            <Chip
                color="green"
                label={`${AppUtils.getDurationStrByDurationMinShort(
                    (salaryPeriodMinutes + salaryPeriodDaybookingMinutes),
                    true
                )} ${t("units.AbrHours")}`}
            />
        </Stack>
    );
};
