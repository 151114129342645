import * as React from "react";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import { Translations } from "../../models/translations";

interface ColumnDef<T> {
    /**
     * id is used for identifying a column for sorting etc. Can be omitted if acccessor is a string.
     */
    id?: string;
    label?: string;
    accessor?: keyof T | ((row: T) => any);
    width?: string | number;
    renderCell?: (val: any, row: T) => React.ReactNode;
    disabledSort?: boolean;
}

export interface TableDef<T> {
    columns: ColumnDef<T>[];
    onRowClick?: (row: T) => void;
    sortBy?: {
        id: string;
        desc: boolean;
    };
    rowsPerPage?: number;
}

const sortOrder = {
    ASC: "asc",
    DESC: "desc",
} as const;

type sortDirection = typeof sortOrder[keyof typeof sortOrder];

const labelRowsPerPage = <span className="align-self-end">{Translations.ScrollLabelRowsPerPage}</span>;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
    "&:last-child td, &:last-child th": { border: 0 },
    selected: false,
}));

const defaultRender = (val: any) => {
    return val == null ? "" : String(val);
};

const isSortable = (col: ColumnDef<any>): boolean => {
    return !col.disabledSort && !!col.accessor;
};

/**
 * Used to identify columns for sorting etc.
 */
const getColumnKey = (col: ColumnDef<any>): string | null => {
    if (col.id) {
        return col.id;
    }
    if (typeof col.accessor === "string") {
        return col.accessor;
    }
    return null;
};

const getCellValue = (col: ColumnDef<any>, row: any) => {
    return typeof col.accessor === "function" ? col.accessor(row) : row[col.accessor];
};

interface Props<T> {
    tableDef: TableDef<T>;
    data: T[];
    disablePagination?: boolean;
}

export const TableWrapper = <T extends object>(props: Props<T>) => {
    const { tableDef, data, disablePagination } = props;
    const { columns } = tableDef;
    const [rows, setRows] = useState<T[]>(data);
    const [page, setPage] = useState<number>(0);
    const [sortDirection, setSortDirection] = useState<sortDirection | null>(null);
    const [sortId, setSortId] = useState<string | null>(null);
    const [rowsPerPage, setRowsPerPage] = useState<number>(!disablePagination ? (props.tableDef.rowsPerPage ?? 26) : data.length);
    const rowCount = data.length;

    useEffect(() => {
        setRows(data);
    }, [data]);

    useEffect(() => {
        if (disablePagination) {
            setRowsPerPage(data.length);
            setPage(0);
        }
    }, [data?.length, disablePagination]);

    const handlePageChange = (
        _event: any,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSort = (col: ColumnDef<any>) => {
        if (!isSortable(col)) return;

        const newSortId = getColumnKey(col);
        const isAsc = newSortId !== sortId || sortDirection === "desc";

        const sortedData = [...rows].sort((a, b) => {
            const aValue = getCellValue(col, a);
            const bValue = getCellValue(col, b);

            // Check if the value is a number
            if (typeof aValue === "number" && typeof bValue === "number") {
                return isAsc ? aValue - bValue : bValue - aValue;
            }

            // Fallback to default string comparison
            const result = Intl.Collator().compare(aValue, bValue);
            return isAsc ? result : -result;
        });

        setRows(sortedData);
        setSortId(newSortId);
        setSortDirection(isAsc ? "asc" : "desc");
    };

    let emptyRows = 0;
    if (rows.length > rowsPerPage) {
        emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    }

    return (
        <TableContainer component={"div"}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {columns.map((column, i) => (
                            <TableCell
                                key={column.id ?? i}
                                variant={"head"}
                                sx={{
                                    width: column.width,
                                    "&:hover": {
                                        color: "theme.palette.grey",
                                    }
                                }}
                                className={isSortable(column) ? "cursor-pointer" : ""}
                                onClick={() => handleSort(column)}
                            >
                                {isSortable(column) && sortId && sortDirection &&
                                    <TableSortLabel active={getColumnKey(column) === sortId} direction={sortDirection}/>
                                }
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                        <StyledTableRow
                            key={index}
                            onClick={() => tableDef.onRowClick?.(row)}
                        >
                            {columns.map((column, i) => {
                                const val = getCellValue(column, row);
                                return (
                                    <TableCell component="td" scope="cell" key={getColumnKey(column) ?? i}>
                                        {column.renderCell ? column.renderCell(val, row) : defaultRender(val)}
                                    </TableCell>
                                );
                            })}
                        </StyledTableRow>
                    ))}
                    {emptyRows > 0 && (
                        <StyledTableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={columns.length} />
                        </StyledTableRow>
                    )}
                </TableBody>

                {!disablePagination &&
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                sx={{ border: 0 }}
                                count={rowCount}
                                page={page}
                                onPageChange={handlePageChange}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={labelRowsPerPage}
                                labelDisplayedRows={({ from, to, count }) => null}
                            />
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
    );
};
