import { OrderTypeListItem } from "../models/orderType/orderTypeListItem";
import { OrderTypeEditItem } from "../models/orderType/orderTypeEditItem";
import { OrderTypeCreateItem } from "../models/orderType/orderTypeCreateItem";
import { apiCall, ApiResponse } from "./apiClient";

export const getOrderTypes = (signal?: AbortSignal): Promise<ApiResponse<OrderTypeListItem[]>> => {
    return apiCall<OrderTypeListItem[]>("ordertypes", "GET", undefined, signal);
};

export const getOrderTypeById = (id: string, signal?: AbortSignal): Promise<ApiResponse<OrderTypeEditItem>> => {
    return apiCall<OrderTypeEditItem>(`ordertypes/${id}`, "GET", undefined, signal);
};

export const createOrderType = (orderType: OrderTypeCreateItem, signal?: AbortSignal): Promise<ApiResponse<void>> => {
    return apiCall<void>("ordertypes", "POST", orderType, signal);
};

export const updateOrderType = (id: string, orderType: OrderTypeEditItem, signal?: AbortSignal): Promise<ApiResponse<void>> => {
    return apiCall<void>(`ordertypes/${id}`, "PUT", orderType, signal);
};

export const deleteOrderType = (id: string, signal?: AbortSignal): Promise<ApiResponse<void>> => {
    return apiCall<void>(`ordertypes/${id}`, "DELETE", undefined, signal);
};