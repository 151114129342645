import { t } from "i18next";
import { SalaryRowTypeKindCode } from "../salary/salaryRowTypeItem";
import { Translations } from "../translations";
import { HourBookingCategory, normalWorkPaidCategories } from "./workHoursItem";

export enum WorkTimeListColumnGroup {
    Paid,
    Unpaid,
    Addition,
    Absence,
    Daily,
    Other,
}

export interface WorkTimeListColumn {
    id: string;
    name: string;
    measureUnit: string;
    decimals: number;
    hourBookingCategories: string[];
    salaryRowTypeId?: string;
    useDayBookingHours?: boolean;
    group: WorkTimeListColumnGroup;
    isGroupTotal?: boolean;
    shortName?: string;
}

export interface WorkTimeListGroupedColumns {
    [key: string]: WorkTimeListColumn[];
}

export const getAbsenceSalaryRowTypeKindCodes = () =>
    [SalaryRowTypeKindCode.Absence, SalaryRowTypeKindCode.Vacation].map((c) =>
        c.toString()
    );

export const isColumnGroupToggleable = (group: WorkTimeListColumnGroup) =>
    [WorkTimeListColumnGroup.Paid, WorkTimeListColumnGroup.Unpaid].includes(
        group
    );

export const WorkTimeListCategoryColumns = [
    {
        group: WorkTimeListColumnGroup.Paid,
        label: t("workTime.workTimeListColumn.PaidTotal"),
        labelShort: t("workTime.workTimeListColumn.PaidTotalShort"),
        isTotal: true,
    },
    {
        group: WorkTimeListColumnGroup.Paid,
        category: HourBookingCategory.Normal,
        includedCategories: normalWorkPaidCategories(),
        ignoreSalaryRowTypeId: true, // Use category to filter hours instead of SalaryRowTypeId
    },
    {
        group: WorkTimeListColumnGroup.Paid,
        category: HourBookingCategory.CustomNormalType,
    },
    {
        group: WorkTimeListColumnGroup.Paid,
        category: HourBookingCategory.OvertimeBase,
    },
    {
        group: WorkTimeListColumnGroup.Paid,
        category: HourBookingCategory.PaidWaiting,
        ignoreIfCategory: HourBookingCategory.Normal,
        ignoreSalaryRowTypeId: true,
    },
    {
        group: WorkTimeListColumnGroup.Paid,
        category: HourBookingCategory.PaidBreak,
        ignoreIfCategory: HourBookingCategory.Normal,
        ignoreSalaryRowTypeId: true,
    },
    {
        group: WorkTimeListColumnGroup.Unpaid,
        label: t("workTime.workTimeListColumn.UnpaidTotal"),
        labelShort: t("workTime.workTimeListColumn.UnpaidTotalShort"),
        isTotal: true,
    },
    {
        group: WorkTimeListColumnGroup.Unpaid,
        category: HourBookingCategory.Break,
        ignoreSalaryRowTypeId: true,
        label: Translations.CategoryBreak,
    },
    {
        group: WorkTimeListColumnGroup.Unpaid,
        category: HourBookingCategory.UnPaidWaiting,
        ignoreSalaryRowTypeId: true,
        label: Translations.CategoryUnPaidWaiting,
    },
    {
        group: WorkTimeListColumnGroup.Unpaid,
        category: HourBookingCategory.MealBreak,
        ignoreSalaryRowTypeId: true,
        label: Translations.CategoryMealBreak,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.FixedAddition,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.Evening,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.Night,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.Saturday,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.SundayOrHoliday,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.GrandHoliday,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.CustomAddition,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.PeriodOvertime50,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.PeriodOvertime100,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.WeekOvertime50,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.WeekOvertime100,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.MonthlyOvertime50,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.DailyOvertime50,
    },
    {
        group: WorkTimeListColumnGroup.Addition,
        category: HourBookingCategory.DailyOvertime100,
    },
    {
        group: WorkTimeListColumnGroup.Daily,
        category: HourBookingCategory.DailyAllowance,
    },
    {
        group: WorkTimeListColumnGroup.Daily,
        category: HourBookingCategory.HalfDailyAllowance,
    },
];
