import { Print as PrintIcon } from "@mui/icons-material";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { UnfoldLess as UnfoldLessIcon } from "@mui/icons-material";
import { UnfoldMore as UnfoldMoreIcon } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import React, { useEffect, useMemo, useState } from "react";
import {
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    useTheme
} from "@mui/material";
import { AppUtils } from "../../models/common/appUtils";
import { Translations } from "../../models/translations";
import { Base } from "../../framework/base";
import MuiMenu from "../framework/muiMenu";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import {
    updateWorkTimeListShownColumns,
    setAllEmployeesOpenedState,
    ToggleAllEmployeesOpenedPayload,
    toggleWorkTimeListColumn
} from "../../store/workTimeBetaSlice";
import { TableCellHeader, TableCellSecondaryHeader } from "../framework/styledTableCells";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { StickyFooter } from "../framework/styledTableHeads";
import { WorkTimeListEmployeeRow } from "./workTimeListEmployeeRow";
import { exportElementByIdToPdf } from "../../framework/printingHelper";
import { exportWorkTimeListToExcel } from "../../framework/excelExportHelper";
import * as store from "../../framework/customStore";
import * as storeActions from "../../models/store/storeActions";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { CalendarDay } from "../../models/calendarDay/calendarDay";
import { SalaryPeriodItem } from "../../models/salaryPeriod/salaryPeriodItem";
import { getCalendarDays } from "../../services/calendarDayService";
import { useFetch } from "../../hooks/useFetch";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";
import { WorkShiftDto } from "../../models/workShift/workShift";
import {
    IWorkHoursItem,
} from "../../models/workTime/workHoursItem";
import { useTranslation } from "react-i18next";
import {
    WorkTimeListColumn,
    WorkTimeListColumnGroup,
    WorkTimeListGroupedColumns,
    isColumnGroupToggleable,
} from "../../models/workTime/workTimeList";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import { Tooltip } from "@mui/material";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { OwnerParameters } from "../../models/owner/ownerParameters";

export interface IEmployeeDayData {
    workShiftId: string;
    dayTitle?: string;
    day: Date;
    date?: string;
    isLocked?: boolean;
    workTime?: WorkShiftTimeSlotItem[];
    dayBooking: DayBookingItemBeta[];
    workShifts: WorkShiftDto[];
    workHoursGrouped: IWorkHoursGrouped[];
    startTime?: string;
    endTime?: string;
    link?: string;
}

export interface IWorkTimesGrouped {
    workTimeType: WorkTimeType;
    duration: number;
}

export interface IWorkHoursGrouped {
    column: WorkTimeListColumn;
    workHours?: IWorkHoursItem[];
    calculated?: number;
    workHoursAmountByCategory?: { categoryName: string; amount: number }[];
}

export interface IOpenEmplyeeDateDetailsParameters {
    employeeId: string;
    date: string;
    shoudOpenNewTab: boolean;
}

export interface IWorkTimeListProps {
    lockRows: (workTimeIds: WorkShiftTimeSlotItem[], dayBookingItems: DayBookingItemBeta[]) => void;
    unlockRows: (workTimeIds: WorkShiftTimeSlotItem[], dayBookingItems: DayBookingItemBeta[]) => void;
    employees: EmployeeListItemDto[]; // Contains all employeeGroup employees
    groupedColumns: WorkTimeListGroupedColumns;
    workTimeTypes: WorkTimeType[];
    workShifts: WorkShiftDto[];
    dayBookings: DayBookingItemBeta[];
    workHours: IWorkHoursItem[];
    salaryPeriod: SalaryPeriodItem;
    path: string;
    employeeIds: string[]; // Contains filtered employeeIds
    selectedCostCenterIds: string[]; // Contains filtered costCenterIds
}
export function WorkTimeList(
    props: IWorkTimeListProps
) {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { t } = useTranslation();

    const { selectedCostCenterIds, groupedColumns } = props;
    const columns = useMemo(
        () =>
            Object.values(WorkTimeListColumnGroup).flatMap(
                (g) => groupedColumns[g] ?? []
            ),
        [groupedColumns]
    );
    const costCenterFilterActive = selectedCostCenterIds?.length > 0;

    const [calendarDays, setCalendarDays] = useState<CalendarDay[]>();

    const groupRowsByShift = useMemo(
        () =>
            new OwnerParameters().getOptionWorkTimeApprovalModeByShiftEnabled(),
        []
    );

    const {
        workTimeListShownColumns,
        openedEmployees,
        workTimeListOpenColumnGroups: openColumnGroups,
    } = useAppSelector((state) => state.workTimeBeta);

    const toggleColumnGroup = (group: WorkTimeListColumnGroup) =>
        dispatch(toggleWorkTimeListColumn(group));

    // Don't re-calculate this when employee filter is changed.
    const employeeRows = useMemo(() => {
        // Days calculation is done inside this to avoid dependency and triggering extra calculation.
        const days = [];
        const firstNotIncludedTime = Base.dateAddDays(
            props.salaryPeriod.endDate,
            1
        );
        let day = Base.dateStartOf(
            props.salaryPeriod.startDate,
            "day"
        ).toDate();
        while (Base.isBefore(day, firstNotIncludedTime)) {
            const newDate = Base.dateStartOf(day, "day");
            days.push(newDate.toDate());
            day = Base.dateAddDays(day, 1).toDate();
        }

        const employees = props.employees.filter(
            (x) =>
                props.workShifts.find(
                    (y) =>
                        y.employeeId === x.id &&
                        (costCenterFilterActive
                            ? y.workShiftTimeSlots.filter((x) =>
                                  selectedCostCenterIds.includes(
                                      x?.costCenterId
                                  )
                              ).length > 0
                            : true)
                ) ||
                props.workHours.find(
                    (y) =>
                        y.employeeId === x.id &&
                        (costCenterFilterActive
                            ? selectedCostCenterIds.includes(y?.costCenterId)
                            : true)
                )
        );

        const filterBySelectedCostCenters = (
            item: IWorkHoursItem | WorkShiftTimeSlotItem
        ) => {
            if (!costCenterFilterActive) return true;
            return selectedCostCenterIds.includes(item?.costCenterId);
        };

        const employeeRows = employees.map((employee) => {
            const workHoursCalculated = props.workHours.filter(
                (hb) =>
                    hb.employeeId === employee.id &&
                    filterBySelectedCostCenters(hb)
            );

            const employeeDayBookings = props.dayBookings.filter(
                (d) => d.employeeId === employee.id
            );

            const employeeShifts = props.workShifts.filter(
                (d) => d.employeeId === employee.id
            );

            const employeeDayDatas = days
                .flatMap((x) => {
                    const dateString = Base.dayjsToJsonDate(x);
                    const dayBookings = employeeDayBookings.filter((i) =>
                        Base.isOverlappingDate(
                            i.day,
                            i.getEndDay(),
                            x,
                            x.addDays(1).getTime() - 1,
                            true
                        )
                    );

                    const dayWorkHours = workHoursCalculated.filter(
                        (w) => w.dateStr === dateString
                    );

                    const hoursByShift = Base.groupArray(
                        dayWorkHours,
                        groupRowsByShift ? "workShiftId" : "dateStr"
                    );

                    const shiftIds = Object.keys(hoursByShift).filter(
                        (id) => id !== "null"
                    );

                    // Merge hours without shiftId to the first shift if exists
                    if (shiftIds.length > 0) {
                        hoursByShift[shiftIds[0]] = [
                            ...hoursByShift[shiftIds[0]],
                            ...(hoursByShift["null"] || []),
                        ];
                    } else if (hoursByShift["null"]) {
                        // In case there is only shift without id
                        shiftIds.push("null");
                    }

                    const shifts = shiftIds.map((shiftId, idx) => {
                        const workHoursGrouped = columns.map((x) => {
                            if (x.isGroupTotal) {
                                return { column: x };
                            }

                            let workHours = hoursByShift[shiftId].filter(
                                (w) =>
                                    (x.salaryRowTypeId &&
                                        w.salaryRowTypeId ===
                                            x.salaryRowTypeId) ||
                                    (!x.salaryRowTypeId &&
                                        x.hourBookingCategories.includes(
                                            w.category
                                        ))
                            );

                            // Display day bookings as hours when included in "Tuntikertymä"
                            if (x.useDayBookingHours) {
                                workHours = workHours.map(
                                    (w) =>
                                        // Find the calculated hourly value in case day booking unit is day.
                                        workHoursCalculated.find(
                                            (c) =>
                                                c.dayBookingId ===
                                                    w.dayBookingId &&
                                                x.hourBookingCategories.includes(
                                                    c.category
                                                )
                                        ) ?? {
                                            ...w,
                                            amount:
                                                // If daybookingHours is not found,
                                                // Convert amount to from days to hours and then minutes
                                                w.amount *
                                                (w.dayHoursRatio ?? 1) *
                                                60,
                                        }
                                );
                            }

                            const hoursByCategory =
                                x.hourBookingCategories?.length > 1
                                    ? Base.groupArray(workHours, "category")
                                    : {};

                            const amountByCategory = Object.keys(
                                hoursByCategory
                            ).map((c) => ({
                                categoryName:
                                    hoursByCategory[c][0].categoryName,
                                amount: hoursByCategory[c].reduce(
                                    (acc, cur) => acc + cur.amount,
                                    0
                                ),
                            }));

                            return {
                                column: x,
                                workHours: workHours,
                                workHoursAmountByCategory: amountByCategory,
                                calculated: workHours.reduce(
                                    (acc, cur) => acc + cur.amount,
                                    0
                                ),
                            };
                        });

                        // Get hours for columns which represent group total
                        workHoursGrouped
                            .filter((t) => t.column.isGroupTotal)
                            .forEach((t) => {
                                const groupHours = workHoursGrouped.filter(
                                    (g) =>
                                        !g.column.isGroupTotal &&
                                        g.column.group === t.column.group
                                );

                                t.workHours = groupHours.flatMap(
                                    (g) => g.workHours
                                );
                                t.calculated = groupHours.reduce(
                                    (acc, cur) => acc + cur.calculated,
                                    0
                                );
                            });

                        return {
                            workShiftId: shiftId,
                            day: x,
                            dayBooking: idx === 0 ? dayBookings : [],
                            workShifts: groupRowsByShift
                                ? employeeShifts.filter(
                                      (es) => es.workShiftId === shiftId
                                  )
                                : employeeShifts.filter(
                                      (es) => es.effectiveDate === shiftId
                                  ),
                            workHoursGrouped,
                        };
                    });

                    if (shifts.length === 0) {
                        return [
                            {
                                workShiftId: null,
                                day: x,
                                dayBooking: dayBookings,
                                workShifts: [],
                                workHoursGrouped: [],
                            },
                        ];
                    }

                    // Sorting same day shifts by startTime
                    return shifts.sort((a, b) =>
                        Base.dayjsCompare(
                            a.workShifts[0].startTime,
                            b.workShifts[0].startTime
                        )
                    );
                })
                .filter(
                    (x) =>
                        x.workShifts.length > 0 ||
                        x.dayBooking.length > 0 ||
                        x.workHoursGrouped.some((g) => g.workHours?.length > 0)
                );

            const workHoursGrouped = columns.map((x) => {
                const columnHours = employeeDayDatas
                    .flatMap((d) => d.workHoursGrouped)
                    .filter((g) => g.column.id === x.id);

                const groupedByCategory = Base.groupArray(
                    columnHours.flatMap(
                        (c) => c.workHoursAmountByCategory ?? []
                    ),
                    "categoryName"
                );

                return {
                    column: x,
                    workHours: columnHours.flatMap((c) => c.workHours ?? []),
                    workHoursAmountByCategory: Object.keys(
                        groupedByCategory
                    ).map((categoryName) => ({
                        categoryName,
                        amount: groupedByCategory[categoryName].reduce(
                            (acc, cur) => acc + cur.amount,
                            0
                        ),
                    })),
                    calculated: columnHours.reduce(
                        (acc, cur) => acc + cur.calculated,
                        0
                    ),
                };
            });

            const workTimesGrouped = props.workTimeTypes.map((x) => ({
                workTimeType: x,
                duration: props.workShifts
                    .filter((ws) => ws.employeeId === employee.id)
                    .flatMap((ws) => ws.workShiftTimeSlots)
                    .filter(
                        (wsts) =>
                            !wsts.isCustomType &&
                            x.workTimeTypeId ===
                                (wsts.workTimeTypeId ?? "wtt_null") &&
                            filterBySelectedCostCenters(wsts)
                    )
                    .reduce((acc, cur) => acc + cur.getDurationMinutes(), 0),
            }));

            return {
                employee,
                employeeDayDatas,
                workTimesGrouped,
                workHoursGrouped,
            };
        });

        return employeeRows;
    }, [
        props.workHours,
        selectedCostCenterIds,
        props.workShifts,
        columns,
        groupRowsByShift,
    ]);

    interface FilteredEmployeeRows {
        employeeDayDatas: IEmployeeDayData[];
    }

    const filterEmployeeRowsByCostCenter = (filteredEmployees: FilteredEmployeeRows[], selectedCostCenterIds: string[]): any => {
        return filteredEmployees.map(employee => {
            const filteredEmployeeDayDatas = employee.employeeDayDatas.filter(employeeDayData => {
                return employeeDayData.workHoursGrouped.some(group => {
                    return group.workHours.some(workHour => {
                        return workHour.costCenterId && selectedCostCenterIds.includes(workHour.costCenterId);
                    });
                });
            });

            return {
                ...employee,
                employeeDayDatas: filteredEmployeeDayDatas
            };
        });
    };

    const filteredEmployeeRows = useMemo(
        () => {
            let rows = employeeRows;
            if(costCenterFilterActive)
                rows = filterEmployeeRowsByCostCenter(rows, selectedCostCenterIds);

            return (rows || []).filter((e) =>
                props.employeeIds.includes(e.employee.id)
            );
        },
        [employeeRows, props.employeeIds]
    );

    const allEmployeesOpened = useMemo(
        () => props.employeeIds.every((id) => openedEmployees.includes(id)),
        [openedEmployees, props.employeeIds]
    );

    const columnTotals = useMemo(
        () =>
            (columns || []).map((t) => ({
                column: t,
                sum: filteredEmployeeRows
                    .flatMap((r) =>
                        r.employeeDayDatas.flatMap((d) =>
                            d.workHoursGrouped
                                .filter((w) => w.column?.id === t.id)
                                .map((g) => g.calculated)
                        )
                    )
                    .reduce((acc, curr) => acc + curr, 0),
            })),
        [filteredEmployeeRows, columns]
    );

    const workTimeTypeTotals = useMemo(
        () =>
            (props.workTimeTypes || []).map((t) => ({
                WorkTimeType: t,
                sum: filteredEmployeeRows
                    .flatMap((r) =>
                        r.workTimesGrouped
                            .filter((d) => d.workTimeType?.workTimeTypeId === t.workTimeTypeId)
                            .map((d) => d.duration)
                    )
                    .reduce((acc, curr) => acc + curr, 0),
            })),
        [filteredEmployeeRows, props.workTimeTypes]
    );

    useEffect(() => {
        if (!workTimeListShownColumns) {
            dispatch(
                updateWorkTimeListShownColumns([
                    ...props.workTimeTypes.map(x => x.workTimeTypeId),
                    ...columns.map(x => x.id)
                ])
            );
        }
    }, [props.workTimeTypes, columns]);

    useFetch(
        (signal) =>
            getCalendarDays(
                props.salaryPeriod.startDate,
                props.salaryPeriod.endDate,
                signal
            ),
        {
            onSuccess: (res) => setCalendarDays(res),
            withLoadingIndicator: false,
        },
        [props.salaryPeriod.startDate, props.salaryPeriod.endDate]
    );

    const printButtonPressed = (elementId: string, title = "export") => {
        store.customStore.dispatch(storeActions.showSuccessMessage(Translations.PrintStarted));
        try {
            exportElementByIdToPdf(elementId, title);
        } catch (e) {
            console.log(e);
            store.customStore.dispatch(storeActions.showErrorMessage(e.message));
        }
    };
    const toggleFold = () => {

        const toggleEmployeesOpened = {
            opened: !allEmployeesOpened,
            employeeIds: props.employeeIds
        } as ToggleAllEmployeesOpenedPayload;

        dispatch(
            setAllEmployeesOpenedState(toggleEmployeesOpened)
        );
    };
    const excelExportButtonPressed = (elementId: string, title = "export") => {
        store.customStore.dispatch(
            storeActions.showSuccessMessage(Translations.PrintStarted)
        );
        try {
            exportWorkTimeListToExcel(elementId, title);
        } catch (e) {
            console.log(e);
            store.customStore.dispatch(
                storeActions.showErrorMessage(e.message)
            );
        }
    };

    if (!workTimeListShownColumns) {
        return null;
    }

    const closedColumns = columns
        .filter(
            (col) =>
                !col.isGroupTotal &&
                !openColumnGroups.includes(col.group) &&
                isColumnGroupToggleable(col.group)
        )
        .map((col) => col.id);
    const shownColumns = workTimeListShownColumns.filter(c => !closedColumns.includes(c));
    const shownWorkTimeTypesCount = props.workTimeTypes.filter(x => workTimeListShownColumns.includes(x.workTimeTypeId)).length;
    const printTitle =
        Base.dateToDateStr(new Date(props.salaryPeriod.startDate)) +
        " - " +
        Base.dateToDateStr(new Date(props.salaryPeriod.endDate)) +
        " - " +
        (props.employeeIds.length === 1
            ? props.employees.find((e) => e.id === props.employeeIds[0]).fullName
            : "");

    return (
        <TableContainer
            component={Paper}
            sx={{ flexGrow: 1, minHeight: 0, overflow: "scroll" }}
            id={"workTimeList"}
            className="styled-scrollbar"
        >
            <Table size="small" stickyHeader>
                <TableHead sx={{ position: "sticky", top: "0", zIndex: 3 }}>
                    <TableRow>
                        <TableCellHeader
                            className="primary-header"
                            style={{position: "sticky", top: 0, left:0, zIndex: 4}}
                        >
                            <div className="d-flex tool-panel">
                                <IconButton
                                    color="primary"
                                    sx={{ color: theme.palette.common.white }}
                                    onClick={() => {
                                        toggleFold();
                                    }}
                                >
                                    {allEmployeesOpened ? (
                                        <UnfoldLessIcon />
                                    ) : (
                                        <UnfoldMoreIcon />
                                    )}
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    sx={{ color: theme.palette.common.white }}
                                    onClick={() => {
                                        printButtonPressed(
                                            "workTimeListContainer",
                                            printTitle
                                        );
                                    }}
                                >
                                    <PrintIcon />
                                </IconButton>
                                <IconButton
                                    color="primary"
                                    sx={{ color: theme.palette.common.white }}
                                    onClick={() => {
                                        excelExportButtonPressed(
                                            "workTimeList",
                                            printTitle
                                        );
                                    }}
                                >
                                    <FileDownloadIcon />
                                </IconButton>
                                <MuiMenu
                                    label={
                                        <SettingsIcon
                                            sx={{
                                                color: theme.palette.common
                                                    .white,
                                            }}
                                        />
                                    }
                                    keepOpenAfterSelect
                                    items={[
                                        ...columns.map((x) => {
                                            return {
                                                label: x.name,
                                                icon: workTimeListShownColumns.includes(
                                                    x.id
                                                ) ? (
                                                    <CheckBoxIcon color="secondary" />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon color="secondary" />
                                                ),
                                                onClick: () => {
                                                    if (
                                                        workTimeListShownColumns.includes(
                                                            x.id
                                                        )
                                                    ) {
                                                        dispatch(
                                                            updateWorkTimeListShownColumns(
                                                                workTimeListShownColumns.filter(
                                                                    (y) =>
                                                                        y !==
                                                                        x.id
                                                                )
                                                            )
                                                        );
                                                    } else {
                                                        dispatch(
                                                            updateWorkTimeListShownColumns(
                                                                [
                                                                    ...workTimeListShownColumns,
                                                                    x.id,
                                                                ]
                                                            )
                                                        );
                                                    }
                                                },
                                            };
                                        }),
                                        { divider: true },
                                        ...props.workTimeTypes.map((x) => {
                                            return {
                                                label: x.name,
                                                icon: workTimeListShownColumns.includes(
                                                    x.workTimeTypeId
                                                ) ? (
                                                    <CheckBoxIcon color="secondary" />
                                                ) : (
                                                    <CheckBoxOutlineBlankIcon color="secondary" />
                                                ),
                                                onClick: () => {
                                                    if (
                                                        workTimeListShownColumns.includes(
                                                            x.workTimeTypeId
                                                        )
                                                    ) {
                                                        dispatch(
                                                            updateWorkTimeListShownColumns(
                                                                workTimeListShownColumns.filter(
                                                                    (y) =>
                                                                        y !==
                                                                        x.workTimeTypeId
                                                                )
                                                            )
                                                        );
                                                    } else {
                                                        dispatch(
                                                            updateWorkTimeListShownColumns(
                                                                [
                                                                    ...workTimeListShownColumns,
                                                                    x.workTimeTypeId,
                                                                ]
                                                            )
                                                        );
                                                    }
                                                },
                                            };
                                        }),
                                    ]}
                                />
                            </div>
                        </TableCellHeader>
                        <TableCellHeader className="primary-header" />
                        <TableCellHeader className="primary-header" />
                        <TableCellHeader className="primary-header" />
                        <TableCellHeader className="primary-header" />
                        <TableCellHeader className="primary-header" />
                        {Object.keys(WorkTimeListColumnGroup).map(
                            (k) =>
                                groupedColumns[
                                    WorkTimeListColumnGroup[k]
                                ]?.filter((x) => shownColumns.includes(x.id))
                                    .length > 0 && (
                                    <TableCellHeader
                                        key={k}
                                        style={{
                                            borderLeft:
                                                "2px solid var(--palette-TableCell-border)",
                                        }}
                                        colSpan={
                                            groupedColumns[
                                                WorkTimeListColumnGroup[k]
                                            ]?.filter((x) =>
                                                shownColumns.includes(x.id)
                                            ).length
                                        }
                                        className="primary-header"
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                {t(
                                                    `workTime.workTimeListColumnGroup.${k}`
                                                )}
                                            </Box>
                                            {isColumnGroupToggleable(
                                                WorkTimeListColumnGroup[k]
                                            ) ? (
                                                <IconButton
                                                    color="white"
                                                    onClick={() =>
                                                        toggleColumnGroup(
                                                            WorkTimeListColumnGroup[
                                                                k
                                                            ]
                                                        )
                                                    }
                                                >
                                                    {openColumnGroups.includes(
                                                        WorkTimeListColumnGroup[
                                                            k
                                                        ]
                                                    ) ? (
                                                        <ArrowBackIcon />
                                                    ) : (
                                                        <ArrowForwardIcon />
                                                    )}
                                                </IconButton>
                                            ) : null}
                                        </Box>
                                    </TableCellHeader>
                                )
                        )}
                        {shownWorkTimeTypesCount > 0 && (
                            <TableCellHeader
                                style={{
                                    borderLeft:
                                        "2px solid var(--palette-TableCell-border)",
                                }}
                                colSpan={shownWorkTimeTypesCount}
                                className="primary-header"
                            >
                                {Translations.WorkTimeTypes}
                            </TableCellHeader>
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCellSecondaryHeader />
                        <TableCellSecondaryHeader />
                        <TableCellSecondaryHeader />
                        <TableCellSecondaryHeader>
                            {Translations.StartTime}
                        </TableCellSecondaryHeader>
                        <TableCellSecondaryHeader>
                            {Translations.EndTime}
                        </TableCellSecondaryHeader>
                        <TableCellSecondaryHeader>
                            {t("workShiftDuration")}
                        </TableCellSecondaryHeader>
                        {Object.values(WorkTimeListColumnGroup).map((g) =>
                            groupedColumns[g]
                                ?.filter((x) => shownColumns.includes(x.id))
                                .map((x, i) => (
                                    <TableCellSecondaryHeader
                                        style={{
                                            borderLeft:
                                                i === 0
                                                    ? "2px solid var(--palette-TableCell-border)"
                                                    : "2px solid var(--palette-grayBlue-main)",
                                        }}
                                        key={i}
                                        className="hour-cell"
                                    >
                                        <Tooltip
                                            title={`${x.name} (${x.measureUnit})`}
                                        >
                                            <div>
                                                {x.shortName ??
                                                    `${x.name} (${x.measureUnit})`}
                                            </div>
                                        </Tooltip>
                                    </TableCellSecondaryHeader>
                                ))
                        )}
                        {props.workTimeTypes
                            .filter((x) =>
                                workTimeListShownColumns.includes(
                                    x.workTimeTypeId
                                )
                            )
                            .map((x, i) => (
                                <TableCellSecondaryHeader
                                    style={{
                                        borderLeft:
                                            i === 0
                                                ? "2px solid var(--palette-TableCell-border)"
                                                : "2px solid var(--palette-grayBlue-main)",
                                    }}
                                    key={i}
                                    className="hour-cell"
                                >
                                    <Tooltip title={x.name}>
                                        <div>{x.name}</div>
                                    </Tooltip>
                                </TableCellSecondaryHeader>
                            ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredEmployeeRows.map((employee) => (
                        <WorkTimeListEmployeeRow
                            key={employee.employee.id}
                            employee={employee.employee}
                            shownColumns={shownColumns}
                            employeeDayDatas={employee.employeeDayDatas}
                            workShifts={props.workShifts.filter(
                                (ws) => ws.employeeId === employee.employee.id
                            )}
                            columns={columns}
                            workTimeTypes={props.workTimeTypes}
                            workTimesGrouped={employee.workTimesGrouped}
                            workHoursGrouped={employee.workHoursGrouped}
                            lockRows={props.lockRows}
                            unlockRows={props.unlockRows}
                            calendarDays={calendarDays}
                            path={props.path}
                        />
                    ))}
                </TableBody>
                <StickyFooter className={"sticky-footer"}>
                    <TableRow>
                        <TableCellSecondaryHeader
                            className="sticky-header-cell-left"
                            style={{
                                borderTop:
                                    "1px solid var(--palette-grayBlue-main)",
                            }}
                        >
                            {Translations.Total}
                        </TableCellSecondaryHeader>
                        <TableCellSecondaryHeader
                            style={{
                                borderTop:
                                    "1px solid var(--palette-grayBlue-main)",
                            }}
                        />
                        <TableCellSecondaryHeader
                            style={{
                                borderTop:
                                    "1px solid var(--palette-grayBlue-main)",
                            }}
                        />
                        <TableCellSecondaryHeader
                            style={{
                                borderTop:
                                    "1px solid var(--palette-grayBlue-main)",
                            }}
                        />
                        <TableCellSecondaryHeader
                            style={{
                                borderTop:
                                    "1px solid var(--palette-grayBlue-main)",
                            }}
                        />
                        <TableCellSecondaryHeader
                            style={{
                                borderTop:
                                    "1px solid var(--palette-grayBlue-main)",
                            }}
                        />
                        {(columnTotals || [])
                            .filter((x) => shownColumns.includes(x.column.id))
                            .map((x, i) => (
                                <TableCellSecondaryHeader
                                    key={x.column.id}
                                    style={{
                                        borderTop:
                                            "1px solid var(--palette-grayBlue-main)",
                                        borderLeft:
                                            i === 0
                                                ? "2px solid var(--palette-TableCell-border)"
                                                : "2px solid var(--palette-grayBlue-main)",
                                    }}
                                >
                                    <span style={{ fontWeight: "bold" }}>
                                        {AppUtils.getDisplayedNumberBasedOnUnit(
                                            x.sum,
                                            x.column.measureUnit,
                                            x.column.decimals,
                                            true
                                        )}
                                    </span>
                                </TableCellSecondaryHeader>
                            ))}
                        {(workTimeTypeTotals || [])
                            .filter((t) =>
                                workTimeListShownColumns.includes(
                                    t.WorkTimeType.workTimeTypeId
                                )
                            )
                            .map((t, i) => (
                                <TableCellSecondaryHeader
                                    key={t.WorkTimeType.workTimeTypeId}
                                    style={{
                                        borderTop:
                                            "1px solid var(--palette-grayBlue-main)",
                                        borderLeft:
                                            i === 0
                                                ? "2px solid var(--palette-TableCell-border)"
                                                : "2px solid var(--palette-grayBlue-main)",
                                    }}
                                >
                                    <span style={{ fontWeight: "bold" }}>
                                        {t.sum
                                            ? AppUtils.getDurationStrByDurationMinShort(
                                                  t.sum
                                              )
                                            : "-"}
                                    </span>
                                </TableCellSecondaryHeader>
                            ))}
                    </TableRow>
                </StickyFooter>
            </Table>
        </TableContainer>
    );
}
