export const formConfig = {
    deactivatedFormInputs: ["array", "time"],
    tooltipDescriptions: {
        add: "Lisää kenttä",
        cardObjectName: "Yksilöivä tunnus kentälle, ei näytetä lomakkeella. Käytä ns. camelCase kirjoitustapaa esim. orderName ilman ääkkösiä ja erikoismerkkejä",
        cardDisplayName: "Kentän näkyvä nimi. Näytetään lomakkeella",
        cardDescription: "Kuvaus kentästä. Näytetään lomakkeella kentän yhteydessä",
        cardInputType: "Kentän datan tyyppi",
        cardSectionObjectName: "Yksilöivä tunnus osiolle. Käytä ns. camelCase kirjoitustapaa esim. orderDetails ilman ääkkösiä ja erikoismerkkejä",
        cardSectionDisplayName: "Osion näkyvä nimi. Näytetään lomakkeella",
        cardSectionDescription: "Osion kuvaus. Näytetään lomakkeella",
    },
    labels: {
        formNameLabel: "Nimi",
        formDescriptionLabel: "Kuvaus",
        objectNameLabel: "Kentän yksilöivä ID",
        displayNameLabel: "Kentän näkyvä nimi",
        descriptionLabel: "Kentän näkyvä kuvaus",
        inputTypeLabel: "Kentän tyypin valinta",
        addElementLabel: "Lisää kenttä",
        addSectionLabel: "Lisää osio",
    },
    newElementDefaultDataOptions: {
        title: "Kenttä",
        type: "string"
    }
};

