import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import {
    GRID_TREE_DATA_GROUPING_FIELD,
    GridColDef,
    GridComparatorFn,
    GridRenderCellParams,
    GridValueFormatterParams,
} from "@mui/x-data-grid-premium";
import { t } from "i18next";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Base } from "../../framework/base";
import { useAppSelector } from "../../framework/customStore";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { useSelectCalendarDay } from "../../hooks/useCalendar";
import { AppUtils } from "../../models/common/appUtils";
import { SalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { SalaryItem } from "../../models/workHourSalary/salaryItem";
import { WorkHourSalaryItem } from "../../models/workHourSalary/workHourSalaryItem";
import { getWorkHourSalaries } from "../../services/workHourSalaryService";
import { fetchCalendarDays } from "../../store/calendarSlice";
import { GroupedDataGrid } from "../framework/groupedDataGrid/groupedDataGrid";
import { SalaryTransferDialog } from "./salaryTransferDialog";

interface GridRow {
    id: string;
    hierarchy: string[];
    salary: SalaryItem;
    workHourSalaries: WorkHourSalaryItem[];
    date: string;
    totals: Record<string, number>;
}

export function formatWorkTime(value: number, measureUnit: string, decimals?: number) {
    if (AppUtils.isTimeUnit(measureUnit)) {
        return (value / 60).toLocaleFixed(decimals ?? 2);
    }
    return value.toLocaleFixed(decimals ?? 0);
}

export const formatTime = (time: string, date: string) =>
    Base.isSameDay(Base.dateStrToDayjsIgnoreTimezone(time), date)
        ? Base.dateStrToOriginalTimezoneTimeStr(time)
        : Base.dateStrToOriginalTimezoneDateTimeStr(time);

interface SalaryListGridProps {
    salaryRowTypes: SalaryRowTypeItem[];
}

export const SalariesListGrid = (props: SalaryListGridProps) => {
    const [salaries, setSalaries] = useState<SalaryItem[]>([]);
    const filters = useAppSelector(state => state.workTimeBeta.filters);
    const fetchFilters = useAppSelector(state => state.workTimeBeta.fetchFilters);
    const salaryRowTypes = props.salaryRowTypes;
    const [transferDialogOpen, setTransferDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataInitialized, setDataInitialized] = useState(false);

    const displayedSalaries = useMemo(() => {
        return salaries.filter(
            (s) =>
                filters.selectedEmployees.length === 0 ||
                filters.selectedEmployees.includes(s.employeeId)
        );
    }, [salaries, filters.selectedEmployees]);

    const loadSalaries = (signal?: AbortSignal) => {
        if (!fetchFilters.selectedSalaryPeriod || !filters.selectedEmployeeGroup) {
            setSalaries([]);
            return;
        }

        setLoading(true);
        getWorkHourSalaries({
            salaryPeriodId: fetchFilters.selectedSalaryPeriod.id,
            employeeGroupId: filters.selectedEmployeeGroup.id
        }, signal)
            .then((res) => {
                setSalaries(res.data);
                setDataInitialized(true);
            })
            .catch((err) => {
                console.log("error", err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(loadSalaries, [filters.selectedEmployeeGroup, fetchFilters.selectedSalaryPeriod]);

    useAsyncThunkAction(
        () => {
            if (!fetchFilters.selectedSalaryPeriod) {
                return;
            }
            return fetchCalendarDays({
                startDate: fetchFilters.selectedSalaryPeriod.startDate,
                endDate: fetchFilters.selectedSalaryPeriod.endDate
            });
        },
        { onError: () => null },
        [fetchFilters.selectedSalaryPeriod]
    );

    const salaryLookup = new Map(salaries.map(s => [s.salaryId, s]));
    const baseData = displayedSalaries.flatMap(s => s.workHourSalaries.map(whs =>
        ({
            id: `${s.salaryId}_${whs.workHourSalaryId}`,
            salary: s,
            workHourSalary: whs,
        })));

    // Group salaries by date
    const groupedData = _groupBy(baseData, (whs) => `${whs.salary.salaryId}_${whs.workHourSalary.date}`);

    // Create grid rows, calculate totals for each group
    const gridRows: GridRow[] = _map(groupedData, (value, key) => {
        const salary = value[0].salary;
        const date = value[0].workHourSalary.date;
        return {
            id: key,
            hierarchy: [salary.salaryId, date],
            salary: value[0].salary,
            date,
            workHourSalaries: value.map(v => v.workHourSalary),
            totals: value.reduce((acc, curr) => {
                acc[curr.workHourSalary.salaryRowTypeId] = (acc[curr.workHourSalary.salaryRowTypeId] ?? 0) + curr.workHourSalary.amount;
                return acc;
            }, {})
        };
    }).sort((a, b) => Base.stringCompare(a.date, b.date));

    const gridDef: GridColDef<GridRow>[] = useMemo(() => [
        ...salaryRowTypes.map((srt): GridColDef<GridRow> => ({
            field: srt.id,
            headerName: `${srt.name} (${srt.measureUnit})`,
            description: `${srt.name} (${srt.measureUnit})`,
            groupable: false,
            sortable: false, // Disable sorting from UI
            type: "number",
            minWidth: 100,
            flex: 1,
            valueGetter(params) {
                const divisor = AppUtils.isTimeUnit(srt.measureUnit) ? 60 : 1;
                return (params.row?.totals?.[srt.id] ?? 0) / divisor;
            },
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                const value = params.value;
                if (!value) return "-";
                return value.toLocaleFixed(srt.decimals ?? 2, "fi-Fi");
            }
        }))
    ], []);

    const onSendToErp = () => {
        setTransferDialogOpen(true);
    };

    const getGroupLabel = useCallback(
        (value: string) => {
            if (value.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)) {
                // val is employeeId
                const salary = salaries.find((s) => s.salaryId === value);
                return salary?.employeeName || "-";
            } else {
                // val is date
                return value;
            }
        },
        [salaries]
    );

    const groupValueFormatter = useCallback(
        (data: GridValueFormatterParams) => getGroupLabel(data.value ?? ""),
        [getGroupLabel]
    );

    const groupingSortComparator: GridComparatorFn<string> = useCallback(
        (a: string, b: string) =>
            Base.stringCompare(getGroupLabel(a), getGroupLabel(b)),
        [getGroupLabel]
    );

    return (
        <div style={{ padding: "1rem" }} id="salary-container">
            <Typography variant="h3">Palkat</Typography>
            <Stack direction="row" alignItems="center">
                <Box>{loading && "Ladataan..."}</Box>
            </Stack>

            {dataInitialized && (
                <GroupedDataGrid
                    noRowsText="Valinnoilla ei löytynyt palkkarivejä"
                    detailsComponent={SalaryDetailsTable}
                    columns={gridDef}
                    rows={gridRows}
                    groupingColDef={{
                        headerName: t("employee.employee"),
                        valueFormatter: groupValueFormatter,
                        sortable: false, // Disable sorting from UI
                        sortComparator: groupingSortComparator,
                        renderCell: (params: GridRenderCellParams<GridRow>) => (
                            <GroupingCol
                                salaryLookup={salaryLookup}
                                params={params}
                            />
                        ),
                    }}
                    persistStateKey="SalaryGridState"
                    defaultSortModel={[
                        { field: GRID_TREE_DATA_GROUPING_FIELD, sort: "asc" },
                    ]}
                />
            )}

            <Stack
                m={2}
                direction="row"
                alignItems="center"
                justifyContent="end"
            >
                <Button
                    variant="contained"
                    color="green"
                    disabled={displayedSalaries.length === 0}
                    onClick={onSendToErp}
                >
                    Siirrä maksettavaksi
                </Button>
            </Stack>

            <SalaryTransferDialog
                salaries={displayedSalaries}
                open={transferDialogOpen}
                onClose={() => setTransferDialogOpen(false)}
            />
        </div>
    );
};

interface GroupingColProps {
    salaryLookup: Map<string, SalaryItem>;
    params: GridRenderCellParams<GridRow>;
}

const GroupingCol = (props: GroupingColProps) => {
    const { salaryLookup, params } = props;
    const calendarDay = useSelectCalendarDay(params.row.date);

    if (params.rowNode.type === "group") {
        const open = params.rowNode.childrenExpanded ?? false;
        const salary = salaryLookup.get(params.value as string);
        return (
            <div>
                {open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                {salary.employeeName}
                <SalaryStateIndicator salary={salary} />
            </div>
        );
    } else if (params.rowNode.type === "leaf") {
        return (
            <Box ml={2}>
                <Link
                    title={calendarDay?.isHoliday ? calendarDay.description : ""}
                    onClick={(e) => e.stopPropagation()}
                    style={{ color: calendarDay?.isHoliday ? "red" : "inherit" }}
                    target="_blank" to={`/worktimepage/details/${params.row.salary.employeeId}/${params.row.date}`}
                >
                    {Base.dayjsToDateStrWithWeekday(params.row.date)}
                </Link>
            </Box>
        );
    }
};

interface SalaryDetailsProps {
    row: GridRow;
}

const SalaryDetailsTable = (props: SalaryDetailsProps) => {
    return (
        <Box sx={{ p: 3, width: "fit-content", position: "sticky", left: 0 }}>
            <Typography variant="h3">Palkkarivit</Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("workTime.time")}</TableCell>
                            <TableCell>{t("workTime.amount")}</TableCell>
                            <TableCell colSpan={2}>
                                {t("workTime.salaryRowType")}
                            </TableCell>
                            <TableCell>{t("workTime.costCenter")}</TableCell>
                            <TableCell>{t("workTime.workTimeType")}</TableCell>
                            <TableCell>{t("vehicle.vehicle")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.row.workHourSalaries.map(
                            (whs: WorkHourSalaryItem) => (
                                <TableRow key={whs.workHourSalaryId}>
                                    <TableCell>
                                        {!!whs.startTime
                                            ? `${formatTime(
                                                  whs.startTime,
                                                  whs.date
                                              )} - ${formatTime(
                                                  whs.endTime,
                                                  whs.date
                                              )}`
                                            : "-"}
                                    </TableCell>
                                    <TableCell>
                                        {formatWorkTime(
                                            whs.amount,
                                            whs.measureUnit,
                                            whs.decimals
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {whs.salaryRowTypeCode ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                        {whs.salaryRowTypeName ?? ""} (
                                        {whs.measureUnit})
                                    </TableCell>
                                    <TableCell>
                                        {whs.costCenterName ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                        {whs.workTimeTypeName ?? "-"}
                                    </TableCell>
                                    <TableCell>
                                        {whs.vehicleName ?? "-"}
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};


interface SalaryStateIndicatorProps {
    salary: SalaryItem;
}

const SalaryStateIndicator = (props: SalaryStateIndicatorProps) => {
    const { salary } = props;

    if (salary.transferDate) {
        return (
            <Tooltip title="Palkka siirretty" disableInteractive>
                <CheckIcon fontSize="small" htmlColor="green" />
            </Tooltip>
        );
    }
};
