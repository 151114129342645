import React from "react";
import Alert from "@mui/material/Alert";
import CircleIcon from "@mui/icons-material/Circle";
import { getWorkTimeTypeColorClass } from "../../models/workShitTimeSlot/workTimeType";
import { useTranslation } from "react-i18next";
import { MuiSelectableList } from "../framework/muiSelectableList";

import { SidebarProps, VehicleData } from "../../models/monitorView/monitorViewTypes";

export const MonitorViewVehiclesSidebar = ({
    data,
    selectedId: selectedVehicleId,
    setSelectedId: setSelectedVehicleId,
}: SidebarProps<VehicleData>) => {
    const { t } = useTranslation();

    return (
        data?.length > 0 ? (
            <MuiSelectableList
                selected={selectedVehicleId}
                items={data?.map((v) => ({
                    id: v.vehicleId,
                    icon: (
                        <CircleIcon
                            className={`text-${getWorkTimeTypeColorClass(
                                    v.workTimeTypeType
                                )}`}
                        />
                    ),
                    text: [
                        v.registerNumber,
                        v.employeeName,
                        v.workTimeTypeName,
                    ]
                        .filter(Boolean)
                        .join(" - "),
                    onSelect: setSelectedVehicleId,
                }))}
            />
        ) : (
            <Alert variant="standard" severity="info">
                {t("vehicle.noRows")}
            </Alert>
        )
    );
};
