import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
    Autocomplete, Box,
    Checkbox,
    Chip,
    CircularProgress,
    IconButton,
    InputAdornment, TextField,
    TextFieldProps,
    SxProps
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Translations } from "../../models/translations";

export interface AutocompleteOption {
    id: string;
    title: string;
    description?: string;
    extra?: string;
}

export type AutocompleteValue = AutocompleteOption | AutocompleteOption[];

export interface Props {
    label?: string;
    options: AutocompleteOption[];
    size?: "small" | "medium";
    multiple?: boolean;
    required?: boolean;
    open?: boolean;
    autoFocus?: boolean;
    textFieldProps?: TextFieldProps;
    icon?: JSX.Element;
    limitTags?: number;
    value?: string[] | string;
    sx?: SxProps;
    loading?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    onChange: (value: AutocompleteValue | null) => void;
    onBlur?: (e: any) => void;
    onAddNew?: () => void;
    onTagClick?: (option: AutocompleteOption) => void;
}

export const AutocompleteWrapper = (props: Props) => {
    const { label, options, size, multiple, required, textFieldProps, loading, icon, limitTags, value, open, autoFocus, sx, disabled, onChange, onBlur, onAddNew, onTagClick } = props;
    const [isOpen, setIsOpen] = useState<boolean>(!!open);

    // TODO option to use local state instead of calling onChange on every keystroke to improve performance

    return (
        <Autocomplete
            multiple={multiple}
            loading={loading}
            size={size ?? "medium"}
            limitTags={limitTags}
            disableCloseOnSelect={multiple}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            options={options}
            getOptionLabel={(option: AutocompleteOption) => option.title}
            renderOption={(props, option: AutocompleteOption, { selected }) => (
                <li {...props} key={option.id}>
                    {multiple &&
                        <Checkbox
                            sx={{ p: 0, mr: 1 }}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                        />
                    }
                    <Box sx={{ flexGrow: 1 }}>
                        {option.title}
                        {option.description &&
                            <>
                                <br />
                                <span className="text-gray">{option.description}</span>
                            </>
                        }
                    </Box>
                    {multiple &&
                        <Box
                            component={CloseIcon}
                            sx={{ opacity: 0.6, width: 18, height: 18 }}
                            style={selected ? null : { display: "none" }}
                        />
                    }
                </li>
            )}
            noOptionsText={Translations.NoOptions}
            loadingText={Translations.Loading}
            value={(multiple ? options.filter(item => value?.includes(item.id)) : options.find(item => value === item.id)) ?? null}
            isOptionEqualToValue={(option: AutocompleteOption, value: AutocompleteOption) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    required={required}
                    fullWidth={props.fullWidth}
                    className={props.fullWidth ? "field-width-fluid" : "field-width-normal"}
                    autoFocus={autoFocus}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment:
    <InputAdornment sx={{ marginLeft: "auto" }} position="end" style={{ minHeight: 26 }}>
        {(value && (!multiple || value.length > 0)) && !disabled &&
        <IconButton size="small" onClick={() => onChange(multiple ? [] : null)}>
            <CloseIcon fontSize="small" sx={{ opacity: 0.6 }} />
        </IconButton>
                                }
        {(!multiple && value && onTagClick) &&
        <IconButton
            onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
                onTagClick(options.find(option => option.id === value));
            }}
            color="primary"
        >
            <EditIcon sx={{ height: 16 }} />
        </IconButton>
                                }
        {onAddNew &&
        <IconButton
            onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
                onAddNew();
            }}
            color="primary"
        >
            <AddIcon />
        </IconButton>
                                }
        {loading && <CircularProgress color="inherit" size={20} />}
        <IconButton size="small" color="primary" onClick={() => setIsOpen(!isOpen)} disabled={disabled}>
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>
    </InputAdornment>,
                        style: { paddingRight: 4 },
                    }}
                    {...textFieldProps}
                />
            )}
            renderTags={(value: AutocompleteOption[], getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={option.id}
                        variant="filled"
                        icon={icon}
                        label={option.title}
                        onClick={onTagClick ? () => {
                            setIsOpen(false);
                            onTagClick(option);
                        } : undefined}
                        {...getTagProps({ index })}
                    />
                ))
            }
            onChange={(e, value: AutocompleteValue) => {
                e.stopPropagation(); // Don't trigger clicks on parent component
                onChange(value);
            }}
            onBlur={onBlur}
            sx={sx}
            disabled={disabled}
        />
    );
};
