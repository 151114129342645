import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { AppUtils } from "../../models/common/appUtils";
import { Base } from "../../framework/base";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { Chip, Stack, Typography } from "@mui/material";
import { useSelectPaidWorkHoursAmountByDate } from "../../hooks/workTime/useSelectWorkHours";
import { useTranslation } from "react-i18next";

export const WorkHoursPerWeek = () => {
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const { t } = useTranslation();

    const weekNumber = useMemo(() => Base.getWeekNumber(date), [date]);
    const startDate = useMemo(
        () => Base.dayjsToJsonDate(Base.dateStartOf(date, "week")),
        [date]
    );
    const endDate = useMemo(
        () => Base.dayjsToJsonDate(Base.dateEndOf(date, "week")),
        [date]
    );

    const weekMinutes = useSelectPaidWorkHoursAmountByDate(
        employeeId,
        startDate,
        endDate
    );

    return (
        <Stack direction="row" alignItems="center" gap={2}>
            <Typography variant="h4">
                {t("week")}: {weekNumber}
            </Typography>
            <Chip
                color="green"
                label={`${AppUtils.getDurationStrByDurationMinShort(
                    weekMinutes,
                    true
                )} ${t("units.AbrHours")}`}
            />
        </Stack>
    );
};
