export interface ICostCenterItem {
    id: string;
    number: number;
    name: string;
    description: string;
    activeState: number;
    customerId: string;
    customerName: string;
}

export class CostCenterItem implements ICostCenterItem {
    id: string;
    number: number;
    name: string;
    description: string;
    activeState: number;
    customerId: string;
    customerName: string;

    constructor();
    constructor(obj: ICostCenterItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.number = obj && obj.number || 0;
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.activeState = obj && obj.activeState || 0;
        this.customerId = obj && obj.customerId || "";
        this.customerName = obj && obj.customerName || "";
    }
}