import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { FormBuilder } from "@ginkgo-bioworks/react-json-schema-form-builder";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Translations } from "../../../models/translations";
import DynamicForm from "../../orderTypes/dynamicForm";
import { formConfig } from "../../orderTypes/formConfig";
import { createOrderType, updateOrderType, getOrderTypeById } from "../../../services/orderTypeService";
import { MainLayout } from "../../layout/mainLayout"; // Import MainLayout

import "./settingsOrderTypeDialog.css";

const settingsOrderTypesPath = "/settings/ordertypes/ordertypes";

const OrderTypeForm = () => {
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const [state, setState] = useState({
        orderTypeId: id || "",
        schema: "{}",
        uischema: "{}",
        formValues: {},
    });
    const isEditMode = id !== "new";

    useEffect(() => {
        const fetchOrderType = async() => {
            if (isEditMode) {
                try {
                    const response = await getOrderTypeById(id);
                    const orderType = response.data;
                    setState({
                        orderTypeId: orderType.orderTypeId,
                        schema: orderType.formSchema.schemaDetails, // todo
                        uischema: "{}", // todo
                        formValues: "{}" // todo
                    });
                } catch (error) {
                    console.error("Error fetching order type:", error);
                }
            }
        };
        fetchOrderType().catch(error => console.error("Error in fetchOrderType:", error));
    }, [id, isEditMode]);

    const handleSubmit = async(): Promise<void> => {
        const parsedSchema = JSON.parse(state.schema);
        const title = parsedSchema.title;

        const orderType = {
            orderTypeId: state.orderTypeId === "new" ? undefined : state.orderTypeId,
            title: title,
            formSchema: state.schema,
            uischema: state.uischema,
            formValues: state.formValues,
        };

        try {
            if (isEditMode) {
                await updateOrderType(id, orderType);
            } else {
                await createOrderType(orderType);
            }
            history.push(settingsOrderTypesPath);
        } catch (error) {
            console.error("Error saving order type:", error);
        }
    };

    const topComponent = (
        <Grid2 container spacing={2}>
            <Grid2>
                <Button variant="contained" color="primary" onClick={() => history.push(settingsOrderTypesPath)}>
                    Takaisin
                </Button>
            </Grid2>
            <Grid2>
                <Button variant="contained" color="success" onClick={handleSubmit}>
                    {Translations.Save}
                </Button>
            </Grid2>
        </Grid2>
    );

    const sideComponent = (
        <FormBuilder
            schema={state.schema}
            uischema={state.uischema}
            onChange={(newSchema, newUiSchema) => {
                setState({
                    ...state,
                    schema: newSchema,
                    uischema: newUiSchema
                });
            }}
            mods={formConfig}
        />
    );

    return (
        <MainLayout topComponent={topComponent} sideComponent={sideComponent} sideComponentSide="left">
            <Grid2 container spacing={2} sx={{ padding: "1rem" }}>
                <Grid2 xs={12}>
                    <DynamicForm
                        schema={state.schema ? JSON.parse(state.schema) : {}}
                        uischema={state.uischema ? {
                            ...JSON.parse(state.uischema),
                            "ui:submitButtonOptions": { norender: true },
                        } : {}}
                        onChange={(newFormData) => {
                            setState({
                                ...state,
                                formValues: newFormData,
                            });
                        }}
                        formValues={state.formValues}
                        onSubmit={(values) => console.log(values)}
                    />
                </Grid2>
            </Grid2>
        </MainLayout>
    );
};

export default OrderTypeForm;