import React from "react";
import { Translations } from "../../models/translations";
import { ToolButton } from "./toolButton";

interface CommandRowProps {
    title: string;
    selectedId?: string;
    handleAdd?: () => void;
    handleEdit?: () => void;
    handleRemove?: () => void;
}

export const CommandRow = ({
    title,
    selectedId,
    handleAdd,
    handleEdit,
    handleRemove,
}: CommandRowProps) => {
    return (
        <div className="commandRow">
            <label className="control-label listTitle">{title}</label>
            {!!handleRemove ? (
                <ToolButton
                    title={Translations.Remove}
                    enabled={!!selectedId}
                    classes={"round right remove"}
                    onClick={handleRemove}
                />
            ) : null}
            {!!handleEdit ? (
                <ToolButton
                    title={Translations.Edit}
                    enabled={!!selectedId}
                    classes={"round right edit"}
                    onClick={handleEdit}
                />
            ) : null}
            {!!handleAdd ? (
                <ToolButton
                    title={Translations.Add}
                    enabled
                    classes={"round right add"}
                    onClick={handleAdd}
                />
            ) : null}
        </div>
    );
};
